<template>
  <div>
    <div class="view1">
      <div class="selectBtn-view">
        <div class="type-item mr-70">
          对比指标：
          <div class="type-item-btn">
            <span
              v-for="(item,index) in indicatorList"
              :key="index"
              @click="setIndicator(index)"
              :class="[indicatorValue === index ? 'active': '']"
            >{{item}}</span>
          </div>
        </div>
        <div class="type-item mr-70">
          对比店铺：
          <div class="type-item-select no-select">
            <el-select
              popper-class="w-block-select-down yellow-select-panel"
              v-model="currentStore.storeName"
              size="small"
              :disabled="true"
            >
              <el-option
                v-for="(item, index) in storeOptions"
                :key="index"
                :label="item.storeName"
                :value="index"
              >
              </el-option>
            </el-select>
          </div>
          <span class="VS">VS</span>
          <div class="type-item-select">
            <el-select
              popper-class="w-block-select-down yellow-select-panel"
              v-model="compareStore.storeName"
              filterable
              size="small"
              placeholder="请选择"
              @change="selectCompareStore"
            >
              <el-option
                v-for="(item, index) in storeList"
                :key="index"
                :label="item.storeName"
                :value="item.storeName"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="type-item small">
          时间范围：
          <div class="type-item-select timeLimit">
            <el-select
              popper-class="w-block-select-down"
              v-model="timeType"
              size="small"
              placeholder="请选择"
              @change="selectTimeRange"
            >
              <el-option
                v-for="item in timeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <span class="segmentation">|</span>
            <el-date-picker
              popper-class="orange-el-date-picker"
              :disabled="noEdit"
              v-model="startTime"
              type="date"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptions_start"
              @change="setStartTime()"
            >
            </el-date-picker>
            <span :class="noEdit?'isDisabled':''">~</span>
            <el-date-picker
              popper-class="orange-el-date-picker"
              :disabled="noEdit"
              v-model="endTime"
              type="date"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptions_end"
              @change="setEndTime()"
            >
            </el-date-picker>
          </div>
        </div>
      </div>
      <div class="contrast">
        <div class="contrast-chart">
          <div class="contrast-checkBox">
            <span class="contrast-title">店铺销售对比</span>
          </div>
          <div
            id="charts"
            class="charts-box"
          ></div>
        </div>
        <div class="contrast-data">
          <span class="contrast-title">支付渠道对比</span>
          <ul class="fr">
            <li class="fl">
              <span></span>
              {{currentStore.storeName?currentStore.storeName:'-'}}
            </li>
            <li class="fl">
              <span></span>
              {{compareStore.storeName?compareStore.storeName:'-'}}
            </li>
          </ul>
          <div
            class="contrast-content"
            v-for="(item,index) in contrastList"
            :key="index"
          >
            <div class="contrast-content-title">
              <span>{{item.percent1}}</span>
              <span>{{item.name}}</span>
              <span>{{item.percent2}}</span>
            </div>
            <div class="doubleProgress">
              <div class="progress-bg">
                <div
                  :style="{
                    'width': item.progress1,
                  }"
                  class="progress-l"
                >
                </div>
              </div>
              <div class="progress-bg">
                <div
                  :style="{
                    'width': item.progress2,
                  }"
                  class="progress-r"
                >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="view2">
      <span class="contrast-title">销售货品对比</span>
      <!-- <span
        class="exportOut fr"
        @click="exportOutFunc()"
      >导出数据</span>
      <div class="clear"></div> -->
      <div
        class="w-table"
        :class="showTableData?'w-singed-table':'grey-singed-table'"
        @scroll="loadMore($event)"
      >
        <table>
          <thead>
            <tr>
              <th colspan="4">
                {{currentStore.storeName?currentStore.storeName:'-'}}
              </th>
            </tr>
            <tr>
              <th width="132">排名</th>
              <th width="178">货品名称</th>
              <th width="210">销售数量</th>
              <th width="283">销售金额(元)</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in tableDataA"
              :key="index"
              :class="item.makeUp?'greyTD':''"
            >
              <td>{{index + 1}}</td>
              <td>{{item.goodsName}}</td>
              <td>{{item.goodsNumber}}</td>
              <td>{{item.goodsAmount}}</td>
            </tr>
          </tbody>
        </table>
        <table>
          <thead>
            <tr>
              <th colspan="4">
                {{compareStore.storeName?compareStore.storeName:'-'}}
              </th>
            </tr>
            <tr>
              <th width="132">排名</th>
              <th width="178">货品名称</th>
              <th width="210">销售数量</th>
              <th width="283">销售金额(元)</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in tableDataB"
              :key="index"
              :class="item.makeUp?'greyTD':''"
            >
              <td>{{index + 1}}</td>
              <td>{{item.goodsName}}</td>
              <td>{{item.goodsNumber}}</td>
              <td>{{item.goodsAmount}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import echarts from 'echarts'
import { dateFormat } from '@/utils/index'
export default {
  name: 'storeContrast',
  data () {
    return {
      indicatorList: ['销售额', '交易笔数', '客单价', '销售坪效'],
      indicatorValue: 0,
      charts: {},
      storeList: '', // 除了本店的店铺列表
      currentChart: '', // 本店图表数据
      compareChart: '', // 对比店图表数据
      currentData: [], // 本店支付渠道数据
      compareData: [], // 对比店铺支付渠道数据
      contrastList: [{ // 进度条数据
        name: '微信支付占比',
        percent1: '-%', // 本店铺(显示数值用)
        progress1: '0%', // 本店铺(设置进度条宽度用)
        percent2: '-%', // 对比店铺(显示数值用)
        progress2: '0%' // 对比店铺(设置进度条宽度用)
      }, {
        name: '支付宝占比',
        percent1: '-%',
        progress1: '0%',
        percent2: '-%',
        progress2: '0%'
      }, {
        name: '现金占比',
        percent1: '-%',
        progress1: '0%',
        percent2: '-%',
        progress2: '0%'
      }, {
        name: '银行卡占比',
        percent1: '-%',
        progress1: '0%',
        percent2: '-%',
        progress2: '0%'
      }, {
        name: '储蓄卡占比',
        percent1: '-%',
        progress1: '0%',
        percent2: '-%',
        progress2: '0%'
      }, {
        name: '优惠券占比',
        percent1: '-%',
        progress1: '0%',
        percent2: '-%',
        progress2: '0%'
      }],
      tableDataA: [],
      currentPage: 1,
      tableDataB: [],
      showTableData: false,
      currentStore: { // 当前店铺
        storeCode: '',
        storeName: ''
      },
      compareStore: { // 对比店铺
        storeCode: '',
        storeName: ''
      },
      timeType: '近7天',
      timeOptions: [{
        value: '近7天',
        label: '近7天'
      }, {
        value: '近30天',
        label: '近30天'
      }, {
        value: '近90天',
        label: '近90天'
      }, {
        value: '近一年',
        label: '近一年'
      }, {
        value: '自定义',
        label: '自定义'
      }],
      startTime: '',
      endTime: '',
      pickerOptions_start: {
        disabledDate: (time) => {
          if (this.endTime) {
            return time.getTime() > Date.now() || time.getTime() > this.endTime
          } else {
            return time.getTime() > Date.now()
          }
        }
      },
      pickerOptions_end: {
        disabledDate: (time) => {
          return time.getTime() < this.startTime || time.getTime() > Date.now()
        }
      },
      noEdit: false,
      isShowChart: true
    }
  },
  props: {
    filtroProject: {
      type: Number
    },
    filtroStore: {
      type: String
    },
    storeOptions: {
      type: Array
    }
  },
  watch: {
    filtroStore (newValue, oldValue) {
      this.setCurrentData()
    }
  },
  mounted () {
    this.setCurrentTime()
    this.setDefaultDate()
    this.setDefaultChart()
    this.setCurrentData()
  },
  methods: {
    // 省缺时显示的数据
    setDefaultDate () {
      this.tableDataA = []
      this.tableDataB = []
      this.showTableData = false
      for (let i = 0; i < 5; i++) {
        const obj = {
          goodsName: '-',
          goodsNumber: '-',
          goodsAmount: '-',
          makeUp: true
        }
        this.tableDataA.push(obj)
        this.tableDataB.push(obj)
      }
    },
    // 生成时间范围数组
    getEveryDayDateByBetweenDate (start, end) {
      const startTime = new Date(start).getTime()
      const endTime = new Date(end).getTime()
      const dateList = []
      for (let i = startTime; i <= endTime; i += 3600 * 1000 * 24 * 1) {
        const time = dateFormat('yyyy-MM-dd', new Date(i))
        dateList.push(time)
      }
      return dateList
    },
    setDefaultChart () {
      this.currentChart = []
      const Arr = this.getEveryDayDateByBetweenDate(this.startTime, this.endTime)
      for (let i = 0; i < Arr.length; i++) {
        const obj = {
          salesDate: Arr[i],
          salesAmount: '',
          orderCount: '',
          unitPrice: '',
          salesEffect: ''
        }
        this.currentChart.push(obj)
      }
    },
    // 设置初始值
    setCurrentData () {
      // 除了本店铺的其他店铺
      this.storeList = this.storeOptions.filter(item => {
        return item.storeCode !== this.filtroStore
      })
      // 当前店铺
      this.currentStore.storeCode = this.filtroStore
      const Temp = this.storeOptions.filter(item => {
        return item.storeCode === this.filtroStore
      })
      this.currentStore.storeName = Temp[0].storeName
      // 获取默认加载店铺
      const params = {
        projectId: this.filtroProject,
        storeCode: this.filtroStore,
        type: 2
      }
      this.axios.get(api.getBaseInfoDefaultCompareStore, { params: params }).then(res => {
        if (res.data.code === 0) {
          if (res.data.data.storeCode) {
            this.compareStore.storeCode = res.data.data.storeCode
            const Arr = this.storeOptions.filter(item => {
              return item.storeCode === res.data.data.storeCode
            })
            this.compareStore.storeName = Arr[0].storeName
          }
          this.getData()
        }
      })
    },
    // 设置时间初始值
    setCurrentTime () {
      // 设置默认时间
      const end = new Date()
      const start = new Date()
      end.setTime(end.getTime() - 3600 * 1000 * 24 * 1)
      this.endTime = dateFormat('yyyy-MM-dd', end)
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
      this.startTime = dateFormat('yyyy-MM-dd', start)
      this.noEdit = true
    },
    // 获取数据
    getData () {
      // 重置数据
      this.currentPage = 1
      this.currentChart = ''
      this.compareChart = ''
      this.tableDataA = []
      this.tableDataB = []
      // 1.获取本店铺数据
      const params = {
        projectId: this.filtroProject,
        storeCode: this.filtroStore,
        salesStartDate: this.startTime,
        salesEndDate: this.endTime,
        toCompareStoreCode: ''
      }
      this.axios.get(api.getStoreSaleCompare, { params: params }).then(res => {
        if (res.data.code === 0) {
          this.isShowChart = true
          const resData = res.data.data
          // 本店支付渠道对比
          this.currentData = []
          this.currentData.push(resData.salesCompareTenderRadioVo)
          // 获取图表数据
          this.currentChart = resData.salesCompareSalesDetailVoList
          // 2.获取对比店铺数据
          if (this.compareStore.storeCode) {
            const params2 = {
              projectId: this.filtroProject,
              storeCode: this.compareStore.storeCode,
              salesStartDate: this.startTime,
              salesEndDate: this.endTime,
              toCompareStoreCode: this.filtroStore
            }
            this.axios.get(api.getStoreSaleCompare, { params: params2 }).then(res => {
              if (res.data.code === 0) {
                const resData = res.data.data
                // 获取支付渠道对比
                this.compareData = []
                this.compareData.push(resData.salesCompareTenderRadioVo)
                // 获取图表数据
                this.compareChart = resData.salesCompareSalesDetailVoList
                this.getContrast() // 支付渠道数据赋值处理
                this.showChart() // 图表
              }
            })
          } else {
            this.getContrast() // 支付渠道数据赋值处理
            this.showChart() // 图表
          }
        } else {
          this.isShowChart = false
          this.setDefaultChart()
          this.showChart() // 图表
        }
        // this.showChart() // 图表
      })
      // 3.获取销售货品分析
      this.getStoreSalesGoodsPageList()
    },
    // 加载更多
    loadMore (e) {
      if (e.srcElement.scrollTop + e.srcElement.clientHeight >= e.srcElement.scrollHeight) {
        this.getStoreSalesGoodsPageList()
      }
    },
    // 获取销售货品分析
    getStoreSalesGoodsPageList () {
      const params1 = { // 获取本店铺货品列表
        projectId: this.filtroProject,
        storeCode: this.currentStore.storeCode,
        salesStartDate: this.startTime,
        salesEndDate: this.endTime,
        currentPage: this.currentPage,
        pageSize: 10
      }
      const params2 = { // 获取对比店铺货品列表
        projectId: this.filtroProject,
        storeCode: this.compareStore.storeCode,
        salesStartDate: this.startTime,
        salesEndDate: this.endTime,
        currentPage: this.currentPage,
        pageSize: 10
      }
      this.axios.get(api.getStoreSalesGoodsPageList, { params: params1 }).then(res => { // 获取本店铺货品列表
        if (res.data.code === 0) {
          this.showTableData = true
          const resData = res.data.data
          if (this.tableDataA.length < resData.total) {
            const Arr = resData.dataList.map(item => {
              return {
                goodsName: item.goodsName,
                goodsNumber: item.goodsNumber,
                goodsAmount: item.goodsAmount,
                makeUp: false // 判断是否是补差值的数据
              }
            })
            this.tableDataA.push(...Arr)
          }
          this.axios.get(api.getStoreSalesGoodsPageList, { params: params2 }).then(resource => { // 获取对比店铺货品列表
            if (resource.data.code === 0) {
              const resourceData = resource.data.data
              if (this.tableDataB.length < resourceData.total) {
                const Arr = resourceData.dataList.map(item => {
                  return {
                    goodsName: item.goodsName,
                    goodsNumber: item.goodsNumber,
                    goodsAmount: item.goodsAmount,
                    makeUp: false
                  }
                })
                this.tableDataB.push(...Arr)
              }
              if (this.tableDataA.length > 0 || this.tableDataB.length > 0) {
                // 补空值，为了让页面表格好看一些
                const differenceValue = this.tableDataA.length - this.tableDataB.length
                const value = Math.abs(differenceValue)
                if (differenceValue > 0) {
                  for (let i = 0; i < value; i++) {
                    const temp = {
                      goodsName: '-',
                      goodsNumber: '-',
                      goodsAmount: '-',
                      makeUp: true
                    }
                    this.tableDataB.push(temp)
                  }
                } else if (differenceValue < 0) {
                  for (let i = 0; i < value; i++) {
                    const temp = {
                      goodsName: '-',
                      goodsNumber: '-',
                      goodsAmount: '-',
                      makeUp: true // 判断是否是补差值的数据
                    }
                    this.tableDataA.push(temp)
                  }
                }
              } else { // 缺省
                this.setDefaultDate()
              }
              // 页码增加
              this.currentPage++
            }
          })
        } else {
          this.setDefaultDate()
        }
      })
    },
    setIndicator (val) {
      this.indicatorValue = val
      this.showChart()
    },
    selectTimeRange (val) {
      this.timeType = val
      const end = new Date()
      const start = new Date()
      end.setTime(end.getTime() - 3600 * 1000 * 24 * 1)
      this.endTime = dateFormat('yyyy-MM-dd', end)
      switch (val) {
        case '近7天':
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
          this.startTime = dateFormat('yyyy-MM-dd', start)
          this.noEdit = true
          this.getData()
          break
        case '近30天':
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
          this.startTime = dateFormat('yyyy-MM-dd', start)
          this.noEdit = true
          this.getData()
          break
        case '近90天':
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
          this.startTime = dateFormat('yyyy-MM-dd', start)
          this.noEdit = true
          this.getData()
          break
        case '近一年':
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 365)
          this.startTime = dateFormat('yyyy-MM-dd', start)
          this.noEdit = true
          this.getData()
          break
        case '自定义':
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
          this.startTime = dateFormat('yyyy-MM-dd', start)
          this.noEdit = false
          this.getData()
          break
      }
    },
    selectCompareStore (val) {
      const Arr = this.storeList.filter(item => {
        return item.storeName === val
      })
      this.compareStore.storeCode = Arr[0].storeCode
      this.compareStore.storeName = Arr[0].storeName
      this.getData()
    },
    setStartTime () {
      const start = new Date(this.startTime).getTime()
      const end = new Date(this.endTime).getTime()
      const difference = (end - start) / (1000 * 60 * 60 * 24)
      if (this.endTime) {
        if (start > end) {
          this.$message({
            type: 'warning',
            message: '开始时间不能大于结束时间'
          })
          return false
        }
        if (difference > 365) {
          this.$message({
            type: 'warning',
            message: '时间范围最大不超过1年'
          })
          return false
        }
        this.getData()
      }
    },
    setEndTime () {
      const start = new Date(this.startTime).getTime()
      const end = new Date(this.endTime).getTime()
      const difference = (end - start) / (1000 * 60 * 60 * 24)
      if (this.startTime) {
        if (start > end) {
          this.$message({
            type: 'warning',
            message: '开始时间不能大于结束时间'
          })
          return false
        }
        if (difference > 365) {
          this.$message({
            type: 'warning',
            message: '时间范围最大不超过1年'
          })
          return false
        }
        this.getData()
      }
    },
    showChart () {
      switch (this.indicatorValue) {
        case 0: // 销售额
          // 图表数据处理
          if ((this.currentChart && this.currentChart.length > 0) || (this.compareChart && this.compareChart.length > 0)) {
            const obj = {
              indicator: '销售额', // y轴名
              unit: '万元', // y轴名单位
              store1: this.currentStore.storeName ? this.currentStore.storeName : '-', // 本店铺名字
              store2: this.compareStore.storeName ? this.compareStore.storeName : '-', // 对比店铺名字
              label: [], // 横坐标
              value1: [], // 第一条线的值
              value2: [] // 第二条线的值
            }
            if (this.currentChart && this.currentChart.length > 0) {
              for (let i = 0; i < this.currentChart.length; i++) {
                obj.label.push(this.currentChart[i].salesDate)
                obj.value1.push((this.currentChart[i].salesAmount || this.currentChart[i].salesAmount === 0) ? (this.currentChart[i].salesAmount / 10000).toFixed(2) : '') // 单位转换，元转万元
              }
            }
            if (this.compareChart && this.compareChart.length > 0) {
              for (let i = 0; i < this.compareChart.length; i++) {
                obj.value2.push((this.compareChart[i].salesAmount || this.compareChart[i].salesAmount === 0) ? (this.compareChart[i].salesAmount / 10000).toFixed(2) : '') // 单位转换，元转万元
              }
            }
            this.getChart(obj)
          } else {
            this.isShowChart = false
            this.setDefaultChart()
          }
          break
        case 1: // 交易笔数
          // 图表数据处理
          if ((this.currentChart && this.currentChart.length > 0) || (this.compareChart && this.compareChart.length > 0)) {
            const obj = {
              indicator: '交易笔数', // y轴名
              unit: '单', // y轴名单位
              store1: this.currentStore.storeName ? this.currentStore.storeName : '-', // 本店铺名字
              store2: this.compareStore.storeName ? this.compareStore.storeName : '-', // 对比店铺名字
              label: [], // 横坐标
              value1: [], // 第一条线的值
              value2: [] // 第二条线的值
            }
            if (this.currentChart && this.currentChart.length > 0) {
              for (let i = 0; i < this.currentChart.length; i++) {
                obj.label.push(this.currentChart[i].salesDate)
                obj.value1.push(this.currentChart[i].orderCount)
              }
            }
            if (this.compareChart && this.compareChart.length > 0) {
              for (let i = 0; i < this.compareChart.length; i++) {
                obj.value2.push(this.compareChart[i].orderCount)
              }
            }
            this.getChart(obj)
          } else {
            this.isShowChart = false
            this.setDefaultChart()
          }
          break
        case 2: // 客单价
          // 图表数据处理
          if ((this.currentChart && this.currentChart.length > 0) || (this.compareChart && this.compareChart.length > 0)) {
            const obj = {
              indicator: '客单价', // y轴名
              unit: '元', // y轴名单位
              store1: this.currentStore.storeName ? this.currentStore.storeName : '-', // 本店铺名字
              store2: this.compareStore.storeName ? this.compareStore.storeName : '-', // 对比店铺名字
              label: [], // 横坐标
              value1: [], // 第一条线的值
              value2: [] // 第二条线的值
            }
            if (this.currentChart && this.currentChart.length > 0) {
              for (let i = 0; i < this.currentChart.length; i++) {
                obj.label.push(this.currentChart[i].salesDate)
                obj.value1.push(this.currentChart[i].unitPrice)
              }
            }
            if (this.compareChart && this.compareChart.length > 0) {
              for (let i = 0; i < this.compareChart.length; i++) {
                obj.value2.push(this.compareChart[i].unitPrice)
              }
            }
            this.getChart(obj)
          } else {
            this.isShowChart = false
            this.setDefaultChart()
          }
          break
        case 3: // 销售坪效
          // 图表数据处理
          if ((this.currentChart && this.currentChart.length > 0) || (this.compareChart && this.compareChart.length > 0)) {
            const obj = {
              indicator: '销售坪效', // y轴名
              unit: '元/日/㎡', // y轴名单位
              store1: this.currentStore.storeName ? this.currentStore.storeName : '-', // 本店铺名字
              store2: this.compareStore.storeName ? this.compareStore.storeName : '-', // 对比店铺名字
              label: [], // 横坐标
              value1: [], // 第一条线的值
              value2: [] // 第二条线的值
            }
            if (this.currentChart && this.currentChart.length > 0) {
              for (let i = 0; i < this.currentChart.length; i++) {
                obj.label.push(this.currentChart[i].salesDate)
                obj.value1.push(this.currentChart[i].salesEffect)
              }
            }
            if (this.compareChart && this.compareChart.length > 0) {
              for (let i = 0; i < this.compareChart.length; i++) {
                obj.value2.push(this.compareChart[i].salesEffect)
              }
            }
            this.getChart(obj)
          } else {
            this.isShowChart = false
            this.setDefaultChart()
          }
          break
      }
    },
    // 画图
    getChart (obj) {
      // console.log('obj', obj)
      this.charts = echarts.init(document.getElementById('charts'))
      this.charts.setOption(this.chartsOptionLine(obj))
      this.charts.setOption({
        legend: {
          data: [obj.store1, obj.store2]
        },
        xAxis: [
          {
            data: obj.label
          }
        ],
        series: [
          {
            name: obj.store1,
            type: 'line',
            smooth: true,
            emphasis: {
              focus: 'series'
            },
            lineStyle: {
              color: '#3F8CFF'
            },
            areaStyle: {
              opacity: 1,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: 'rgba(62, 140, 255, .3)'
              }, {
                offset: 1,
                color: 'rgba(62, 140, 255, 0)'
              }])
            },
            data: obj.value1
          },
          {
            name: obj.store2,
            type: 'line',
            smooth: true,
            emphasis: {
              focus: 'series'
            },
            lineStyle: {
              color: 'rgba(160, 215, 231, 1)'
            },
            areaStyle: {
              opacity: 1,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: 'rgba(160, 215, 231, .3)'
              }, {
                offset: 1,
                color: 'rgba(160, 215, 231, 0)'
              }])
            },
            data: obj.value2
          }
        ]
      })
      this.$nextTick(() => {
        window.addEventListener('resize', () => {
          this.charts.resize()
        })
      })
    },
    chartsOptionLine (obj) {
      const maxValue = this.isShowChart ? null : 1750
      const option = {
        color: ['#3F8CFF', '#A0D7E7'],
        legend: {
          itemHeight: 10,
          itemWidth: 10,
          itemGap: 30,
          top: 2,
          right: 20,
          icon: 'circle',
          textStyle: {
            color: 'rgba(255, 255, 255, .45)',
            fontFamily: 'PingFang SC',
            fontSize: 12
          }
        },
        grid: {
          top: '15%',
          left: '5%',
          right: '5%',
          bottom: '1%',
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          backgroundColor: 'rgba(0,0,0,0.85)',
          textStyle: {
            fontSize: 12
          },
          formatter: (params) => {
            let html = '<div style="padding:4px; font-family: PingFang SC;">' + '<p style="color:#b2b3bd; text-align:center;">' + params[0].name + '</p>'
            for (let i = 0; i < params.length; i++) {
              html += '<span style="color:#b2b3bd">' + params[i].marker + params[i].seriesName + '： ' + '</span>' + ((params[i].data || params[i].data === 0) ? params[i].data : '-') + obj.unit + '<br>'
            }
            html += '</div>'
            return html
          }
        },
        xAxis: {
          boundaryGap: false,
          type: 'category',
          offset: 6,
          axisTick: {
            show: false
          },
          axisLabel: {
            color: '#808191'
          },
          axisLine: {
            lineStyle: {
              color: '#373A43'
            }
          },
          axisPointer: {
            lineStyle: {
              color: 'rgba(255,255,255,.1)'
            }
          },
          data: []
        },
        yAxis: [{
          name: obj.indicator + '(' + obj.unit + ')',
          nameTextStyle: {
            color: '#808191',
            fontFamily: 'PingFang SC',
            fontSize: 12
          },
          min: 0,
          max: maxValue,
          type: 'value',
          axisTick: {
            show: false
          },
          splitLine: {
            lineStyle: {
              color: '#373A43',
              type: 'dashed'
            }
          },
          axisLine: {
            lineStyle: {
              color: '#373A43'
            }
          },
          axisLabel: {
            color: '#808191',
            formatter: '{value}'
          }
        }],
        series: []
      }
      return option
    },
    // 进度条数据处理
    getContrast () {
      // 本店铺
      if (this.currentData[0]) {
        this.contrastList[0].percent1 = this.currentData[0].salesWxRadio // 微信
        this.contrastList[0].progress1 = this.currentData[0].salesWxRadio
        this.contrastList[1].percent1 = this.currentData[0].salesZfbRadio // 支付宝
        this.contrastList[1].progress1 = this.currentData[0].salesZfbRadio
        this.contrastList[2].percent1 = this.currentData[0].salesXjRadio // 现金占比
        this.contrastList[2].progress1 = this.currentData[0].salesXjRadio
        this.contrastList[3].percent1 = this.currentData[0].salesYhkRadio // 银行卡占比
        this.contrastList[3].progress1 = this.currentData[0].salesYhkRadio
        this.contrastList[4].percent1 = this.currentData[0].salesCzkRadio // 储蓄卡占比
        this.contrastList[4].progress1 = this.currentData[0].salesCzkRadio
        this.contrastList[5].percent1 = this.currentData[0].salesYhqRadio // 优惠券占比
        this.contrastList[5].progress1 = this.currentData[0].salesYhqRadio
      } else {
        // 重置数据
        for (let i = 0; i < this.contrastList.length; i++) {
          this.contrastList[i].percent1 = '-%'
          this.contrastList[i].progress1 = '0%'
        }
      }
      // 对比店铺
      if (this.compareData[0]) {
        this.contrastList[0].percent2 = this.compareData[0].salesWxRadio // 微信
        this.contrastList[0].progress2 = this.compareData[0].salesWxRadio
        this.contrastList[1].percent2 = this.compareData[0].salesZfbRadio // 支付宝
        this.contrastList[1].progress2 = this.compareData[0].salesZfbRadio
        this.contrastList[2].percent2 = this.compareData[0].salesXjRadio // 现金占比
        this.contrastList[2].progress2 = this.compareData[0].salesXjRadio
        this.contrastList[3].percent2 = this.compareData[0].salesYhkRadio // 银行卡占比
        this.contrastList[3].progress2 = this.compareData[0].salesYhkRadio
        this.contrastList[4].percent2 = this.compareData[0].salesCzkRadio // 储蓄卡占比
        this.contrastList[4].progress2 = this.compareData[0].salesCzkRadio
        this.contrastList[5].percent2 = this.compareData[0].salesYhqRadio // 优惠券占比
        this.contrastList[5].progress2 = this.compareData[0].salesYhqRadio
      } else {
        // 重置数据
        for (let i = 0; i < this.contrastList.length; i++) {
          this.contrastList[i].percent2 = '-%'
          this.contrastList[i].progress2 = '0%'
        }
      }
    }
    // exportOutFunc () {
    //   console.log('导出数据')
    // }
  }
}
</script>

<style lang="stylus" scoped>
.view1
  padding-top 60px
  background #272930
  opacity 1
  border-radius 0 0 2px 2px
  .selectBtn-view
    margin-top 17px
    padding-left 24px
    line-height 33px
    display flex
    flex-flow wrap
    .type-item
      margin-top 15px
      display flex
      font-size 14px
      font-family PingFang SC
      color #B2B3BD
      .type-item-btn
        padding 0 3px
        height 33px
        border 1px solid #373A43
        opacity 1
        border-radius 2px
        span
          display inline-block
          width 77px
          height 27px
          line-height 27px
          font-size 14px
          font-family PingFang SC
          font-weight 500
          color rgba(255, 255, 255, 0.5)
          text-align center
          margin-right -1px
          cursor pointer
          &.active
            background #FFA134
            color #fff
            border-color #FFA134
        span:first-child
          border-radius 2px 0px 0px 2px
        span:last-child
          border-radius 0px 2px 2px 0px
      .type-item-select
        background-color #373A43
        .segmentation
          color #424752
        .isDisabled
          color rgba(128, 129, 145, 0.5)
        span
          color #B2B3BD
        & >>> .el-select
          width 154px
        & >>> .el-date-editor
          width 120px
          input
            text-align center
      .timeLimit >>> .el-select
        width 112px
      .no-select >>> .el-input__suffix
        i
          display none
    .VS
      width 42px
      text-align center
      font-weight 800
      color #808191
  .contrast-checkBox
    position relative
    width 100%
  .contrast
    display flex
    margin-top 63px
    height 570px
    .contrast-data
      width 40%
      ul
        font-size 12px
        font-family PingFang SC
        font-weight 400
        color #808191
        li
          margin-right 24px
          span
            display inline-block
            width 8px
            height 8px
            border-radius 50%
            background #3F8CFF
          &:nth-child(2) span
            background #A0D7E7
      .contrast-content
        margin 40px 24px auto 24px
        .contrast-content-title
          justify-content space-between
          display flex
          margin-bottom 15px
          span
            font-size 16px
            font-family PingFang SC
            color #FFFFFF
          span:nth-child(2)
            font-size 14px
            font-weight 400
            color #B2B3BD
    .contrast-chart
      border-right 1px solid #373A43
      width 60%
      .charts-box
        top -24px
        width 100%
        height 537px
.contrast-title
  padding-left 24px
  font-size 16px
  font-family PingFang SC
  font-weight bold
  color #FFFFFF
.view2
  padding-top 40px
  padding-bottom 40px
  border-top 1px solid #373A43
  background #272930
  .exportOut
    margin-right 25px
    font-size 16px
    font-weight 400
    color #B2B3BD
    cursor pointer
    &:before
      margin-right 6px
      font-family 'iconfont'
      content '\e60b'
  .w-table
    display flex
    justify-content center
    height 345px
    margin-top 24px
    overflow-y auto
    font-size 14px
    font-family PingFang SC
    table
      width 100%
      thead
        tr
          height 49px
          color #B2B3BD
          &:first-child
            font-weight bold
            color #FFF
          th
            border 1px solid #373A43
            text-align center
            span
              color #808191
      tbody
        tr
          height 49px
          color #808191
          font-size 14px
          &:hover td
            background-color #23252b
          td
            border 1px solid #373A43
            text-align center
            &:nth-child(3)
              color #fff
            &:nth-child(4)
              color #fff
            &:nth-child(7)
              color #fff
            &:nth-child(8)
              color #fff
      .greyTD td
        color #808191 !important
    table:first-child
      margin-left 24px
    table:nth-child(2)
      margin-right 24px
    table:nth-child(2) td
      border-left none
    table:nth-child(2) th
      border-left none
.clear
  clear both
.mr-70
  margin-right 70px
// elm-ui样式
// 1.下拉框
/deep/ .el-input__inner
  height 33px
  background-color #373A43
  color #808191
  border none
  border-radius 2px
  padding 0 18px
// 2.日期组件样式
// （1）日期icon
/deep/ .el-input__prefix
  display none
/deep/ .el-date-editor .el-input__suffix
  display none
// （2）输入框禁止点击时
/deep/ .is-disabled .el-input__inner
  color rgba(128, 129, 145, 0.5) !important
  background-color #373a43 !important
</style>
