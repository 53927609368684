<template>
  <div>
    <div class="view1">
      <div class="selectBtn-view">
        <div class="type-item mr-70">
          排名指标：
          <div class="type-item-btn">
            <span
              @click="setIndicatorA('销售额')"
              :class="[indicatorA === '销售额' ? 'active': '']"
            >销售额</span>
            <span
              @click="setIndicatorA('交易笔数')"
              :class="[indicatorA === '交易笔数' ? 'active': '']"
            >交易笔数</span>
            <span
              @click="setIndicatorA('客单价')"
              :class="[indicatorA === '客单价' ? 'active': '']"
            >客单价</span>
            <span
              @click="setIndicatorA('销售坪效')"
              :class="[indicatorA === '销售坪效' ? 'active': '']"
            >销售坪效</span>
          </div>
        </div>
        <div class="type-item mr-70">
          业务范围：
          <div class="type-item-btn">
            <span
              @click="setIndicatorB('同业态')"
              :class="[indicatorB === '同业态' ? 'active': '']"
            >同业态</span>
            <span
              @click="setIndicatorB('全部业态')"
              :class="[indicatorB === '全部业态' ? 'active': '']"
            >全部业态</span>
          </div>
        </div>
        <div class="type-item mr-70">
          楼层范围：
          <div class="type-item-btn">
            <span
              @click="setIndicatorC('同楼层')"
              :class="[indicatorC === '同楼层' ? 'active': '']"
            >同楼层</span>
            <span
              @click="setIndicatorC('全部楼层')"
              :class="[indicatorC === '全部楼层' ? 'active': '']"
            >全部楼层</span>
          </div>
        </div>
        <div class="type-item">
          时间范围：
          <div class="type-item-select">
            <el-select
              popper-class="w-block-select-down yellow-select-panel"
              v-model="timeType"
              size="small"
              placeholder="请选择"
              @change="handleData"
            >
              <el-option
                v-for="item in timeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <span class="segmentation">|</span>
            <!-- 日榜 -->
            <el-date-picker
              v-if="timeType === '日榜'"
              popper-class="orange-el-date-picker"
              v-model="dayTime"
              type="date"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              :picker-options="pickerOptions_day"
              @change="setDate()"
            >
            </el-date-picker>
            <!-- 周榜 -->
            <el-date-picker
              v-if="timeType === '周榜'"
              popper-class="orange-el-date-picker"
              :picker-options="pickerOptions_week"
              v-model="weekTime"
              type="week"
              format="yyyy第WW周"
              placeholder="选择日期"
              @change="setWeek()"
            >
            </el-date-picker>
            <!-- 月榜 -->
            <el-date-picker
              v-if="timeType === '月榜'"
              popper-class="orange-el-date-picker"
              v-model="monthTime"
              type="month"
              placeholder="选择日期"
              value-format="yyyy-MM"
              format="yyyy-MM"
              :picker-options="pickerOptions_month"
              @change="setMonth()"
            >
            </el-date-picker>
            <!--季度时间选择控件 -->
            <jidu-date-picker
              v-if="timeType === '季榜'"
              @quarter="quarter"
              :banDate="banDate"
              :quarterTime="quarterTime"
            ></jidu-date-picker>
            <!-- 年榜 -->
            <el-date-picker
              v-if="timeType === '年榜'"
              popper-class="orange-el-date-picker"
              v-model="yearTime"
              type="year"
              placeholder="选择日期"
              value-format="yyyy"
              format="yyyy"
              :picker-options="pickerOptions_year"
              @change="setYear()"
            >
            </el-date-picker>
          </div>
        </div>
      </div>
      <div class="tableChart">
        <div
          class="w-table"
          :class="showRankList?'w-singed-table':'grey-singed-table'"
        >
          <table v-if="showRankList">
            <thead>
              <tr>
                <th width="8.44%">排名</th>
                <th width="19.39%">店铺名称</th>
                <th width="21.90%">业态</th>
                <th width="11.76%">楼层</th>
                <th
                  v-if="indicatorA==='销售额'"
                  width="9.78%"
                >销售额(元)</th>
                <th
                  v-if="indicatorA==='交易笔数'"
                  width="9.78%"
                >交易笔数(单)</th>
                <th
                  v-if="indicatorA==='客单价'"
                  width="9.78%"
                >客单价(元)</th>
                <th
                  v-if="indicatorA==='销售坪效'"
                  width="9.78%"
                >销售坪效(元/日/㎡)</th>
                <th width="28.80%"></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in tableData"
                :key="index"
                :id="item.isMark?'mark':''"
                :class="item.isMark?'mark':''"
                @click="toBaseClick(item)"
              >
                <!-- 排名前三的用特有的图片 -->
                <td v-if="item.rankIndex === 1">
                  <img src="@/assets/images/storeEarlywarning/salesAnalysis/number1.png">
                </td>
                <td v-else-if="item.rankIndex === 2">
                  <img src="@/assets/images/storeEarlywarning/salesAnalysis/number2.png">
                </td>
                <td v-else-if="item.rankIndex === 3">
                  <img src="@/assets/images/storeEarlywarning/salesAnalysis/number3.png">
                </td>
                <td v-else>{{item.rankIndex}}</td>
                <td>{{item.storeName}}</td>
                <td>{{item.formatsName}}</td>
                <td>{{item.floorName}}</td>
                <td :class="item.isMark?'mark':''">{{item.indicator}}</td>
                <td>
                  <div class="singleProgress">
                    <div class="progress-bg">
                      <div
                        :style="{
                          'width': item.percent + '%',
                          'animation': item.animation
                        }"
                        class="progress"
                      >
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <!-- 无数据省缺时 -->
          <table v-if="!showRankList">
            <thead>
              <tr>
                <th width="8.44%">排名</th>
                <th width="19.39%">店铺名称</th>
                <th width="21.90%">业态</th>
                <th width="11.76%">楼层</th>
                <th
                  v-if="indicatorA==='销售额'"
                  width="9.78%"
                >销售额(元)</th>
                <th
                  v-if="indicatorA==='交易笔数'"
                  width="9.78%"
                >交易笔数(单)</th>
                <th
                  v-if="indicatorA==='客单价'"
                  width="9.78%"
                >客单价(元)</th>
                <th
                  v-if="indicatorA==='销售坪效'"
                  width="9.78%"
                >销售坪效(元/日/㎡)</th>
                <th width="28.80%"></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in tableNoData"
                :key="index"
              >
                <!-- 排名前三的用特有的图片 -->
                <td v-if="item.rankIndex === 1">
                  <img src="@/assets/images/storeEarlywarning/salesAnalysis/number1.png">
                </td>
                <td v-else-if="item.rankIndex === 2">
                  <img src="@/assets/images/storeEarlywarning/salesAnalysis/number2.png">
                </td>
                <td v-else-if="item.rankIndex === 3">
                  <img src="@/assets/images/storeEarlywarning/salesAnalysis/number3.png">
                </td>
                <td v-else>{{item.rankIndex}}</td>
                <td>{{item.storeName}}</td>
                <td>{{item.formatsName}}</td>
                <td>{{item.floorName}}</td>
                <td>{{item.indicator}}</td>
                <td>
                  <div class="singleProgress">
                    <div class="progress-bg"></div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="chartView">
          <div class="rank">
            <div class="title">
              <img
                src="@/assets/images/storeEarlywarning/salesAnalysis/rank.png"
                alt=""
              >
              <span>本周期排名</span>
            </div>
            <div v-show="showRankData">
              <div class="chart">
                <div class="number">{{rankNumber?rankNumber:'-'}}</div>
                <div
                  id="charts1"
                  class="chart-box"
                ></div>
              </div>
              <div class="comparison">
                相对上周期({{lastPeriodTime}})：
                <span>
                  {{LastRankData ? (rankNumberLast ? rankNumberLast + '名':'持平') : '-'}}
                  <i :class="isChangeRank === 2 ? 'icon-data-up': (isChangeRank === 1 ?'icon-data-down' : '')"></i>
                </span>
              </div>
              <div
                class="comparison"
                v-if="timeType !== '年榜'"
              >
                相对去年同期({{lastYearPeriodTime}})：
                <span>
                  {{lastYearRank ? (rankNumberLastYear  ?rankNumberLastYear + '名':'持平') : '-'}}
                  <i :class="isChangeRankYear === 2 ? 'icon-data-up': (isChangeRankYear === 1 ?'icon-data-down' : '')"></i>
                </span>
              </div>
            </div>
            <div
              class="no-rank-data"
              v-show="!showRankData"
            >
              <img
                src="@/assets/images/public/not-plandata.png"
                alt=""
              >
              <p>暂无数据</p>
            </div>
          </div>
          <div class="rent">
            <div class="title">
              <img
                src="@/assets/images/storeEarlywarning/salesAnalysis/money.png"
                alt=""
              >
              <span>本周期{{indicatorA}}</span>
              <span v-if="indicatorA==='销售额'">(万元)</span>
              <span v-if="indicatorA==='交易笔数'">(单)</span>
              <span v-if="indicatorA==='客单价'">(元)</span>
              <span v-if="indicatorA==='销售坪效'">(元/日/㎡)</span>
            </div>
            <div v-show="showIndicatorData">
              <div class="chart">
                <div class="number">{{indicatorData?indicatorData:'-'}}</div>
                <div
                  id="charts2"
                  class="chart-box"
                ></div>
              </div>
              <div class="comparison">
                <!-- 这个箭头方向以后改进 -->
                <!-- 如果对比的是第一名的时候 -->
                相对排名{{reputation}}的{{numberOneStoreName?numberOneStoreName:'商户'}}{{indicatorA}}：
                <span>
                  {{numberOneStoreName?(indicatorDataLast?indicatorDataLast:'持平'): '-'}}
                  <!-- 如果对比的是第一名的时候 -->
                  <i
                    v-if="reputation === '第一'"
                    :class="isChangeIndicator === 2 ? 'icon-data-up': (isChangeIndicator === 1 ?'icon-data-down' : '')"
                  ></i>
                  <!-- 如果对比的是第二名的时候 -->
                  <i
                    v-if="reputation === '第二'"
                    :class="isChangeIndicator === 1 ? 'icon-data-up': (isChangeIndicator === 2 ?'icon-data-down' : '')"
                  ></i>
                </span>
              </div>
              <div class="comparison">
                相对排名内商户的平均{{indicatorA}}：
                <span>
                  {{indicatorDataLastYear?indicatorDataLastYear:'持平'}}
                  <i :class="isChangeIndicatorYear === 1 ? 'icon-data-up': (isChangeIndicatorYear === 2 ?'icon-data-down' : '')"></i>
                </span>
              </div>
            </div>
            <div
              class="no-rank-data"
              v-show="!showIndicatorData"
            >
              <img
                src="@/assets/images/public/not-plandata.png"
                alt=""
              >
              <p>暂无数据</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import { dateFormat } from '@/utils/index'
import dayjs from 'dayjs'
import jiduDatePicker from '@/views/storeEarlywarning/components/jududatepicker.vue'
import echarts from 'echarts'
export default {
  name: 'salesRank',
  data () {
    return {
      indicatorA: '销售额',
      indicatorB: '同业态',
      indicatorC: '同楼层',
      charts1: {},
      charts2: {},
      tableAllData: [],
      tableData: [],
      tableNoData: [],
      showRankList: true,
      storeName: '', // 本店铺名
      currentRankData: '', // 现在的数据
      LastRankData: '',
      lastYearRank: '', // 上一年的数据
      lastPeriodTime: '', // 上周期时间
      lastYearPeriodTime: '', // 上年周期时间
      // 本周期排名
      rankNumber: '', // 排名数值
      isChangeRank: 3, // 显示增减或持平，1为降、2为增、3为不变
      isChangeRankYear: 3, // // 显示增减或持平，1为降、2为增、3为不变
      rankNumberLast: '', // 相对上周期的排名的增值或降值
      rankNumberLastYear: '', // 相对上年周期的排名的增值或降值
      // 排名指标
      indicatorData: '', // 排名指标的值
      isChangeIndicator: 3, // 显示增减或持平，1为增、2为降、3为不变
      isChangeIndicatorYear: 3, // 显示增减或持平，1为增、2为降、3为不变
      indicatorDataLast: '', // 相对上周期的排名指标的增值或降值
      indicatorDataLastYear: '', // 相对上年周期的排名的增值或降值
      numberOneStoreName: '', // 排名第一的店铺名
      reputation: '第一',
      timeType: '日榜',
      timeOptions: [{
        value: '日榜',
        label: '日榜'
      }, {
        value: '周榜',
        label: '周榜'
      }, {
        value: '月榜',
        label: '月榜'
      }, {
        value: '季榜',
        label: '季榜'
      }, {
        value: '年榜',
        label: '年榜'
      }],
      rankType: '',
      currentTime: '',
      markIndex: '',
      dayTime: '',
      weekTimeF: '',
      weekTime: '',
      monthTime: '',
      quarterTime: '',
      yearTime: '',
      banDate: '',
      pickerOptions_day: {
        disabledDate: (time) => {
          return time.getTime() < (this.banDate - 8.64e7) || time.getTime() >= (Date.now() - 8.64e7) // 设置今天不能选，减8.64e7
        }
      },
      pickerOptions_week: {
        firstDayOfWeek: 7,
        disabledDate: (time) => {
          return time.getTime() < (this.banDate - 8.64e7 * 7) || time.getTime() >= (Date.now() - 8.64e7 * 7)
        }
      },
      pickerOptions_month: {
        disabledDate: (time) => {
          const t = new Date().getDate()
          return time.getTime() < this.banDate || time.getTime() >= (Date.now() - 8.64e7 * t)
        }
      },
      pickerOptions_year: {
        disabledDate: (time) => {
          return time.getTime() < (this.banDate - 8.64e7 * 365) || time.getTime() >= (Date.now() - 8.64e7 * 365)
        }
      },
      showRankData: true,
      showIndicatorData: true
    }
  },
  components: {
    jiduDatePicker
  },
  props: {
    filtroProject: {
      type: Number
    },
    filtroStore: {
      type: String
    },
    storeOptions: {
      type: Array
    }
  },
  watch: {
    filtroStore (newValue, oldValue) {
      this.getData()
    }
  },
  mounted () {
    this.setDefaultDate()
    this.getBanDate()
    this.getStoreName()
    this.getChart()
    this.setCurrentTime()
    this.getData()
  },
  methods: {
    getBanDate () {
      const Arr = this.storeOptions.filter(item => {
        return item.storeCode === this.filtroStore
      })
      if (Arr && Arr.length > 0) {
        const date = new Date(Arr[0].rentBeg)
        this.banDate = date.getTime()
      }
    },
    getStoreName () {
      const Arr = this.storeOptions.filter(item => {
        return item.storeCode === this.filtroStore
      })
      if (Arr && Arr.length > 0) {
        this.storeName = Arr[0].storeName
      }
    },
    // 缺省
    setDefaultDate () {
      for (let i = 0; i < 10; i++) {
        const obj = {
          storeName: '-',
          formatsName: '-',
          floorName: '-',
          indicator: '-',
          rankIndex: i + 1
        }
        this.tableNoData.push(obj)
      }
    },
    // 获取周的开始日期和结束日期
    getLastWeek (value) {
      if (value === '' || value === undefined) {
        return value
      }
      if (value.length === 10) {
        value = value * 1000
      }
      const myDate = new Date(value - 7 * 24 * 3600 * 1000 * 1) // 上上周
      const day = myDate.getDay() // 回退7天后是星期几？
      const time = myDate.getDate() - day + (day === 0 ? -6 : 1)
      const startTime = new Date(myDate.setDate(time))
      const endTime = new Date(myDate.setDate(time + 6))
      let startmonth = startTime.getMonth() + 1
      if (startmonth < 10) {
        startmonth = '0' + startmonth
      }
      let startDay = startTime.getDate() - 1
      if (startDay < 10) {
        startDay = '0' + startDay
      }
      let endmonth = endTime.getMonth() + 1
      if (endmonth < 10) {
        endmonth = '0' + endmonth
      }
      let endDay = endTime.getDate() - 1
      if (endDay < 10) {
        endDay = '0' + endDay
      }
      const startDateTime = startTime.getFullYear() + '-' + startmonth + '-' + startDay
      const endDateTime = endTime.getFullYear() + '-' + endmonth + '-' + endDay
      return {
        startDateTime: startDateTime,
        endDateTime: endDateTime
      }
    },
    // 选择日
    setDate () {
      // 处理上周期
      const temp = Date.parse(this.dayTime.replace(/-/g, '/')) // 转换成Data()
      const date = new Date(temp - 3600 * 1000 * 24 * 1)
      this.lastPeriodTime = dateFormat('yyyy-MM-dd', date)
      // 上年周期的时间
      const lastYear = new Date(temp - 3600 * 1000 * 24 * 365)
      this.lastYearPeriodTime = dateFormat('yyyy-MM-dd', lastYear)
      // 调用接口
      this.getData()
    },
    // 选择周, 默认加载的地方好像有点问题, 后面优化
    setWeek () {
      const isNow = dayjs(this.weekTime).diff(dayjs(), 'week')
      if (isNow === -1) { // 默认加载
        // 上周期的时间
        const obj = this.getLastWeek(this.weekTime)
        this.lastPeriodTime = obj.startDateTime.replace(/-/g, '.') + '~' + obj.endDateTime.replace(/-/g, '.')
        // 上年周期时间
        const date = new Date(this.weekTime)
        const year = date.getFullYear() - 1
        const month = date.getMonth() + 1
        const day = date.getDate()
        const lastYear = year + '-' + this.appendZero(month) + '-' + day
        const newdate = new Date(lastYear)
        const obj2 = this.getLastWeek(newdate)
        this.lastYearPeriodTime = obj2.startDateTime.replace(/-/g, '.') + '~' + obj2.endDateTime.replace(/-/g, '.')
      } else { // 点击加载
        // 上周期的时间
        const date = new Date(this.weekTime - 3600 * 1000 * 24 * 7)
        const weekStart = dayjs(date).subtract(1, 'day').startOf('day').add(1, 'second').format('YYYY-MM-DD')
        const weekEnd = dayjs(date).add(5, 'day').endOf('day').format('YYYY-MM-DD')
        this.lastPeriodTime = weekStart.replace(/-/g, '.') + '~' + weekEnd.replace(/-/g, '.')
        // 上年周期的时间
        const lastDate = new Date(this.weekTime)
        const weekStartLastYear = dayjs(lastDate).subtract(1, 'day').startOf('day').add(1, 'second').format('YYYY-MM-DD')
        const weekEndLastYear = dayjs(lastDate).add(5, 'day').endOf('day').format('YYYY-MM-DD')
        const Arr = weekStartLastYear.split('-')
        const Arr2 = weekEndLastYear.split('-')
        const start = (Arr[0] - 1) + '.' + Arr[1] + '.' + Arr[2]
        const end = (Arr2[0] - 1) + '.' + Arr2[1] + '.' + Arr2[2]
        this.lastYearPeriodTime = start + '~' + end
      }
      // 调用接口
      this.getData()
    },
    // 选择季度
    quarter (res) {
      // 调用接口
      if (res) {
        this.quarterTime = res.year + 'Q' + res.season
        this.getData()
      } else {
        this.getData()
      }
      // 处理时间
      const Arr = this.quarterTime.split('Q')
      if (Arr[1] === '2') {
        // 上上个季度
        const date = new Date(Arr[0], 3, 0)
        const year = date.getFullYear()
        const dayEnd = date.getDate()
        this.lastPeriodTime = year + '.01.01' + ' ~ ' + year + '.03.' + this.appendZero(dayEnd)
        // 上一年的上个季度
        const last = new Date(Arr[0] - 1, 6, 0)
        const lastYear = last.getFullYear()
        const lastDayEnd = last.getDate()
        this.lastYearPeriodTime = lastYear + '.04.01' + ' ~ ' + lastYear + '.06.' + this.appendZero(lastDayEnd)
      } else if (Arr[1] === '3') {
        // 上上个季度
        const date = new Date(Arr[0], 6, 0)
        const year = date.getFullYear()
        const dayEnd = date.getDate()
        this.lastPeriodTime = year + '.04.01' + ' ~ ' + year + '.06.' + this.appendZero(dayEnd)
        // 上一年的上个季度
        const last = new Date(Arr[0] - 1, 7, 0)
        const lastYear = last.getFullYear()
        const lastDayEnd = last.getDate()
        this.lastYearPeriodTime = lastYear + '.05.01' + ' ~ ' + lastYear + '.07.' + this.appendZero(lastDayEnd)
      } else if (Arr[1] === '4') {
        // 上上个季度
        const date = new Date(Arr[0], 9, 0)
        const year = date.getFullYear()
        const dayEnd = date.getDate()
        this.lastPeriodTime = year + '.07.01' + ' ~ ' + year + '.09.' + this.appendZero(dayEnd)
        // 上一年的上个季度
        const last = new Date(Arr[0] - 1, 12, 0)
        const lastYear = last.getFullYear()
        const lastDayEnd = last.getDate()
        this.lastYearPeriodTime = lastYear + '.10.01' + ' ~ ' + lastYear + '.12.' + this.appendZero(lastDayEnd)
      } else if (Arr[1] === '1') {
        // 上上个季度
        const date = new Date(Arr[0], 12, 0)
        const year = date.getFullYear() - 1
        const dayEnd = date.getDate()
        this.lastPeriodTime = year + '.10.01' + ' ~ ' + year + '.12.' + this.appendZero(dayEnd)
        // 上一年的上个季度
        const last = new Date(Arr[0] - 1, 3, 0)
        const lastYear = last.getFullYear()
        const lastDayEnd = last.getDate()
        this.lastYearPeriodTime = lastYear + '.01.01' + ' ~ ' + lastYear + '.03.' + this.appendZero(lastDayEnd)
      }
    },
    // 选择月
    setMonth () {
      const Arr = this.monthTime.split('-')
      // 上周期时间
      const dateLast = new Date(Arr[0], Arr[1] - 1, 0)
      const year = dateLast.getMonth() === 0 ? dateLast.getFullYear() - 1 : dateLast.getFullYear()
      const month = dateLast.getMonth() === 0 ? 12 : dateLast.getMonth() + 1
      const day = dateLast.getDate()
      this.lastPeriodTime = year + '.' + this.appendZero(month) + '.' + '01' + ' ~ ' + year + '.' + this.appendZero(month) + '.' + this.appendZero(day)
      // 上年周期
      const date = new Date(Arr[0], Arr[1], 0)
      const lastYear = date.getFullYear() - 1
      const lastMonth = date.getMonth() === 0 ? 1 : date.getMonth() + 1
      const lastDay = date.getDate()
      this.lastYearPeriodTime = lastYear + '.' + this.appendZero(lastMonth) + '.' + '01' + ' ~ ' + lastYear + '.' + this.appendZero(lastMonth) + '.' + this.appendZero(lastDay)
      // 调用接口
      this.getData()
    },
    // 选择年
    setYear () {
      // 上周期时间
      const date = new Date(this.yearTime, 12, 0)
      const year = this.yearTime - 1
      const day = date.getDate()
      this.lastPeriodTime = year + '.01.' + '01' + ' ~ ' + year + '.12.' + this.appendZero(day)
      this.getData()
    },
    // 日期不足两位数，补0
    appendZero (obj) {
      return obj < 10 ? '0' + obj : obj
    },
    getData () {
      // 重置数据
      this.tableData = []
      this.tableAllData = []
      this.rankNumber = ''
      this.isChangeRank = 3
      this.isChangeRankYear = 3
      this.rankNumberLast = ''
      this.rankNumberLastYear = ''
      this.indicatorData = ''
      this.isChangeIndicator = 3
      this.isChangeIndicatorYear = 3
      this.indicatorDataLast = ''
      this.indicatorDataLastYear = ''
      this.currentRankData = ''
      this.LastRankData = ''
      this.lastYearRank = ''
      this.showRankData = true
      this.showIndicatorData = true
      // 处理时间
      switch (this.timeType) {
        case '日榜':
          this.currentTime = this.dayTime
          this.rankType = 'day'
          break
        case '周榜':
          this.rankType = 'week'
          this.currentTime = dateFormat('yyyy', this.weekTime) + '-' + this.getYearWeek(this.weekTime)
          break
        case '月榜':
          this.rankType = 'month'
          this.currentTime = this.monthTime
          break
        case '季榜':
          this.rankType = 'quarter'
          this.currentTime = this.quarterTime
          break
        case '年榜':
          this.rankType = 'year'
          this.currentTime = this.yearTime
          break
      }
      // 获取数据并处理
      const params = {
        projectId: this.filtroProject,
        storeCode: this.filtroStore,
        rankDate: this.currentTime,
        rankType: this.rankType
      }
      this.axios.get(api.getStoreSalesRank, { params: params }).then(res => {
        if (res.data.code === 0) {
          this.showRankList = true
          const resData = res.data.data
          // 排行数据
          if (resData.currentStoreRank) {
            this.currentRankData = resData.storeSalesRankDetailList[resData.currentStoreRank - 1] // 当前店铺排行数据
            this.LastRankData = resData.storePrePeriodSalesRank // 上周期
            this.lastYearRank = resData.storeSamePeriodSalesRank // 上年
            this.showRankList = true
            // 获取列表排行
            if (resData.storeSalesRankDetailList.length > 0) {
              this.tableAllData = resData.storeSalesRankDetailList.map((item) => {
                return {
                  storeName: item.storeName, // 店铺名称
                  storeCode: item.storeCode, // 店铺storeCodecode
                  formatsName: item.formatsName, // 业态名称
                  formats1Code: item.formats1Code, // 一级业态编码
                  floorCode: item.floorCode, // 楼层编码
                  floorName: item.floorName, // 楼层名字
                  salesAmount: item.salesAmount, // 销售金额
                  orderCount: item.orderCount, // 销售笔数
                  unitPrice: item.unitPrice, // 客单价
                  salesEffect: item.salesEffect, // 销售坪效
                  isMark: item.currentStore === '1', // 是否是当前
                  // 销售排名
                  ytlcSalesAmountRank: item.ytlcSalesAmountRank, // 同业态同楼层销售排行
                  ytSalesAmountRank: item.ytSalesAmountRank, //  同业态销售排行
                  lcSalesAmountRank: item.lcSalesAmountRank, // 同楼层销售排行
                  salesAmountRank: item.salesAmountRank, // 销售排行
                  // 销售笔数排行
                  ytlcOrderCountRank: item.ytlcOrderCountRank, // 同业态同楼层销售笔数排行
                  ytOrderCountRank: item.ytOrderCountRank, //  同业态销售笔数排行
                  lcOrderCountRank: item.lcOrderCountRank, // 同楼层销售笔数排行
                  orderCountRank: item.orderCountRank, // 销售笔数排行
                  // 客单价排名
                  ytlcUnitPriceRank: item.ytlcUnitPriceRank, // 同业态同楼层客单价排行
                  ytUnitPriceRank: item.ytUnitPriceRank, //  同业态客单价排行
                  lcUnitPriceRank: item.lcUnitPriceRank, // 同楼层客单价排行
                  unitPriceRank: item.unitPriceRank, // 客单价排行
                  // 销售坪效排行
                  ytlcSalesEffectRank: item.ytlcSalesEffectRank, // 同业态同楼层销售排行
                  ytSalesEffectRank: item.ytSalesEffectRank, //  同业态销售排行
                  lcSalesEffectRank: item.lcSalesEffectRank, // 同楼层销售排行
                  salesEffectRank: item.salesEffectRank, // 销售坪效排行
                  validFlag: item.validFlag //  合同生效失效标识。1-生效中, 2-已失效
                }
              })
              // this.showRank()
            }
          } else {
            this.showRankList = false
          }
          this.showRank()
        } else {
          this.showRankList = false
        }
      })
    },
    setIndicatorA (val) {
      this.indicatorA = val
      if (this.tableData.length) {
        this.showRank()
      }
    },
    setIndicatorB (val) {
      this.indicatorB = val
      this.showRank()
    },
    setIndicatorC (val) {
      this.indicatorC = val
      this.showRank()
    },
    handleData (val) {
      this.timeType = val
      switch (val) {
        case '日榜':
          this.rankType = 'day'
          this.setDate()
          break
        case '周榜':
          this.rankType = 'week'
          this.setWeek()
          break
        case '月榜':
          this.rankType = 'month'
          this.setMonth()
          break
        case '季榜':
          this.rankType = 'quarter'
          this.quarter()
          break
        case '年榜':
          this.rankType = 'year'
          this.setYear()
          break
      }
    },
    setCurrentTime () {
      const date = new Date()
      const yesterday = date.getDate() - 1
      const year = date.getFullYear() // 获取本年
      const seasonYear = date.getFullYear() // 获取本年,计算季度用,为了区分上面的年，另生成一个
      const month = date.getMonth() + 1 // 本月月份
      const lastMonth = month - 1 // 上个月月份
      // 设置默认时间
      // 昨天
      this.dayTime = year + '-' + this.appendZero(month) + '-' + this.appendZero(yesterday)
      // 上周
      const thisWeek = new Date()
      thisWeek.setTime(thisWeek.getTime() - 3600 * 1000 * 24 * 7)
      this.weekTime = thisWeek
      // 上个月
      this.monthTime = year + '-' + this.appendZero(lastMonth)
      // 设置默认季度为上季度
      if (month <= 3) { // 4季度
        const season = 4
        const lastYear = seasonYear - 1
        this.quarterTime = lastYear + 'Q' + season
      } else if (month > 3 && month <= 6) { // 1季度
        const season = 1
        this.quarterTime = seasonYear + 'Q' + season
      } else if (month > 6 && month <= 9) { // 2季度
        const season = 2
        this.quarterTime = seasonYear + 'Q' + season
      } else if (month > 9 && month <= 12) { // 3季度
        const season = 3
        this.quarterTime = seasonYear + 'Q' + season
      }
      // 上年
      this.yearTime = (year - 1).toString()
      // 上周期时间
      const beforeYesterday = new Date()
      beforeYesterday.setTime(beforeYesterday.getTime() - 3600 * 1000 * 24 * 2)
      this.lastPeriodTime = dateFormat('yyyy-MM-dd', beforeYesterday)
      // 上年周期时间
      const lastYear = new Date()
      lastYear.setTime(beforeYesterday - 3600 * 1000 * 24 * 364)
      this.lastYearPeriodTime = dateFormat('yyyy-MM-dd', lastYear)
    },
    // 格式化时间，获取周
    getYearWeek (date) {
      date = dateFormat('yyyy-MM-dd', date) // 将日期转换成yyyy-mm-dd格式
      date = new Date(date)
      var date2 = new Date(date.getFullYear(), 0, 1)
      var day1 = date.getDay()
      if (day1 === 0) day1 = 7
      var day2 = date2.getDay()
      if (day2 === 0) day2 = 7
      const d = Math.round((date.getTime() - date2.getTime() + (day2 - day1) * (24 * 60 * 60 * 1000)) / 86400000)
      return Math.ceil(d / 7)
    },
    showContrast () {
      // 进度条设置加载动画
      // 这段代码可省略，改用CSS的trasision,以后优化
      this.tableData.forEach((element, index) => {
        // 设置百分比
        if (element.indicator && this.tableData[0].indicator) {
          this.tableData[index].percent = (element.indicator / this.tableData[0].indicator * 100).toFixed(2) || 0
        } else {
          this.tableData[index].percent = 0
        }
        // 设置animation
        this.tableData[index].animation = 'percent-right' + Number(index + 1) + ' ' + '2s'
        // 1.创建css keyframes
        const style = document.createElement('style')
        style.setAttribute('type', 'text/css')
        document.head.appendChild(style)
        const sheet = style.sheet
        sheet.insertRule('@keyframes percent-right' + `${index + 1}` + '{from {width: 0} to {width: ' + `${element.percent}` + '%}}', 0)
        if (element.isMark) {
          this.markIndex = index
        }
      })
      // 滑动到当前
      if (this.markIndex >= (this.tableData.length - 5) && this.markIndex <= this.tableData.length) {
        this.$nextTick(() => {
          document.getElementById('mark').scrollIntoView({
            behavior: 'smooth', // 平滑过渡
            block: 'center' // 上边框与视窗顶部平齐。默认值
          })
        })
      } else {
        this.$nextTick(() => {
          document.getElementById('mark').scrollIntoView({
            behavior: 'smooth', // 平滑过渡
            block: 'center' // 上边框与视窗顶部平齐。默认值
          })
        })
      }
    },
    // 排序
    // 销售排行
    sortYtlcSalesAmountRank (a, b) { // 同业态, 同楼层
      return a.ytlcSalesAmountRank - b.ytlcSalesAmountRank
    },
    sortYtSalesAmountRank (a, b) { // 同业态, 全部楼层
      return a.ytSalesAmountRank - b.ytSalesAmountRank
    },
    sortLcSalesAmountRank (a, b) { // 全部业态, 同楼层
      return a.lcSalesAmountRank - b.lcSalesAmountRank
    },
    sortSalesAmountRank (a, b) { // 全部业态, 全部楼层
      return a.salesAmountRank - b.salesAmountRank
    },
    // 销售笔数排行
    sortYtlcOrderCountRank (a, b) { // 同业态, 同楼层
      return a.ytlcOrderCountRank - b.ytlcOrderCountRank
    },
    sortytOrderCountRank (a, b) { // 同业态, 全部楼层
      return a.ytOrderCountRank - b.ytOrderCountRank
    },
    sortLcOrderCountRank (a, b) { // 全部业态, 同楼层
      return a.lcOrderCountRank - b.lcOrderCountRank
    },
    sortOrderCountRank (a, b) { // 全部业态, 全部楼层
      return a.orderCountRank - b.orderCountRank
    },
    // 客单价排名
    sortYtlcUnitPriceRank (a, b) { // 同业态, 同楼层
      return a.ytlcUnitPriceRank - b.ytlcUnitPriceRank
    },
    sortYtUnitPriceRank (a, b) { // 同业态, 全部楼层
      return a.ytUnitPriceRank - b.ytUnitPriceRank
    },
    sortLcUnitPriceRank (a, b) { // 全部业态, 同楼层
      return a.lcUnitPriceRank - b.lcUnitPriceRank
    },
    sortUnitPriceRank (a, b) { // 全部业态, 全部楼层
      return a.unitPriceRank - b.unitPriceRank
    },
    // 销售坪效排行
    sortYtlcSalesEffectRank (a, b) { // 同业态, 同楼层
      return a.ytlcSalesEffectRank - b.ytlcSalesEffectRank
    },
    sortYtSalesEffectRank (a, b) { // 同业态, 全部楼层
      return a.ytSalesEffectRank - b.ytSalesEffectRank
    },
    sortLcSalesEffectRank (a, b) { // 全部业态, 同楼层
      return a.lcSalesEffectRank - b.lcSalesEffectRank
    },
    sortSalesEffectRank (a, b) { // 全部业态, 全部楼层
      return a.salesEffectRank - b.salesEffectRank
    },
    // 不是很友好，以后优化
    // 根据排名指标的值，进行二次排序
    sortIndicator (a, b) {
      return a.indicator - b.indicator
    },
    sortSecond (Arr) {
      let Number1 = Arr.filter(item => {
        return item.rankIndex === 1
      })
      Number1 = Number1.sort(this.sortIndicator).reverse()

      let Number2 = Arr.filter(item => {
        return item.rankIndex === 2
      })
      Number2 = Number2.sort(this.sortIndicator).reverse()

      let Number3 = Arr.filter(item => {
        return item.rankIndex === 3
      })
      Number3 = Number3.sort(this.sortIndicator).reverse()

      let NumberRest = Arr.filter(item => {
        return item.rankIndex !== 1 && item.rankIndex !== 2 && item.rankIndex !== 3
      })
      NumberRest = NumberRest.sort(this.sortIndicator)
      const ArrFinal = Number1.concat(Number2.concat(Number3.concat(NumberRest)))
      return ArrFinal
    },
    // 对比排名、指标值
    compare (a, b) {
      let value = a - b
      let type = ''
      if (value > 0) {
        type = 1
      } else if (value < 0) {
        type = 2
      } else if (value === 0) {
        type = 3
      }
      value = Math.abs(value) // 取绝对值
      return { value, type }
    },
    showRank () {
      // 先重置数据
      this.tableData = []
      this.rankNumber = ''
      this.isChangeRank = 3
      this.isChangeRankYear = 3
      this.isChangeIndicator = 3
      this.isChangeIndicatorYear = 3
      this.rankNumberLast = ''
      this.rankNumberLastYear = ''
      this.rankNumber = ''
      this.indicatorDataLast = ''
      this.indicatorDataLastYear = ''
      this.indicatorData = ''
      this.numberOneStoreName = ''
      this.showRankData = true
      this.showIndicatorData = true
      switch (this.indicatorA) {
        case '销售额':
          // 一、排名数据处理
          if (this.indicatorB === '同业态' && this.indicatorC === '同楼层') {
            if (this.currentRankData) {
              this.showRankData = true
              // 一、数值处理
              // 1.获取当前排名
              this.rankNumber = this.currentRankData.ytlcSalesAmountRank
              // 2.获取对比上个周期
              if (this.LastRankData) {
                const obj = this.compare(this.currentRankData.ytlcSalesAmountRank, this.LastRankData.ytlcSalesAmountRank) // 用现在的排名减过去的排名
                this.isChangeRank = obj.type
                this.rankNumberLast = obj.value
              }
              // 3.获取对比上年年周期
              if (this.lastYearRank) {
                const obj = this.compare(this.currentRankData.ytlcSalesAmountRank, this.lastYearRank.ytlcSalesAmountRank)
                this.isChangeRankYear = obj.type
                this.rankNumberLastYear = obj.value
              }
              // 二、列表排序处理, 排序方法以后优化
              // 1.筛选
              const filterArr = this.tableAllData.filter(item => {
                return item.formats1Code === this.currentRankData.formats1Code && item.floorCode === this.currentRankData.floorCode
              })
              // 2.根据排名数，进行1次排序
              const sortArr1 = filterArr.sort(this.sortYtlcSalesAmountRank)
              // 3.根据指标值，进行2次排序
              const sortArr2 = this.sortSecond(sortArr1)
              // 4.赋值展示
              this.tableData = sortArr2.map((item) => {
                return {
                  storeName: item.storeName,
                  isMark: item.isMark,
                  formatsName: item.formatsName,
                  floorName: item.floorName,
                  indicator: item.salesAmount,
                  rankIndex: item.ytlcSalesAmountRank,
                  storeCode: item.storeCode,
                  validFlag: item.validFlag
                }
              })
              this.showContrast()
            } else {
              this.showRankData = false
            }
          } else if (this.indicatorB === '全部业态' && this.indicatorC === '同楼层') {
            if (this.currentRankData) {
              this.showRankData = true
              // 一、数值处理
              // 1.获取当前排名
              this.rankNumber = this.currentRankData.lcSalesAmountRank
              // 2.获取对比上个周期
              if (this.LastRankData) {
                const obj = this.compare(this.currentRankData.lcSalesAmountRank, this.LastRankData.lcSalesAmountRank) // 用现在的排名减过去的排名
                this.isChangeRank = obj.type
                this.rankNumberLast = obj.value
              }
              // 3.获取对比上年年周期
              if (this.lastYearRank) {
                const obj = this.compare(this.currentRankData.lcSalesAmountRank, this.lastYearRank.lcSalesAmountRank)
                this.isChangeRankYear = obj.type
                this.rankNumberLastYear = obj.value
              }
              // 二、列表排序处理, 排序方法以后优化
              // 1.筛选
              const filterArr = this.tableAllData.filter(item => {
                return item.floorCode === this.currentRankData.floorCode
              })
              // 2.根据排名数，进行1次排序
              const sortArr1 = filterArr.sort(this.sortLcSalesAmountRank)
              // 3.根据指标值，进行2次排序
              const sortArr2 = this.sortSecond(sortArr1)
              // 4.赋值展示
              this.tableData = sortArr2.map((item) => {
                return {
                  storeName: item.storeName,
                  isMark: item.isMark,
                  formatsName: item.formatsName,
                  floorName: item.floorName,
                  indicator: item.salesAmount,
                  rankIndex: item.lcSalesAmountRank,
                  storeCode: item.storeCode,
                  validFlag: item.validFlag
                }
              })
              this.showContrast()
            } else {
              this.showRankData = false
            }
          } else if (this.indicatorB === '同业态' && this.indicatorC === '全部楼层') {
            if (this.currentRankData) {
              this.showRankData = true
              // 一、数值处理
              // 1.获取当前排名
              this.rankNumber = this.currentRankData.ytSalesAmountRank
              // 2.获取对比上个周期
              if (this.LastRankData) {
                const obj = this.compare(this.currentRankData.ytSalesAmountRank, this.LastRankData.ytSalesAmountRank) // 用现在的排名减过去的排名
                this.isChangeRank = obj.type
                this.rankNumberLast = obj.value
              }
              // 3.获取对比上年年周期
              if (this.lastYearRank) {
                const obj = this.compare(this.currentRankData.ytSalesAmountRank, this.lastYearRank.ytSalesAmountRank)
                this.isChangeRankYear = obj.type
                this.rankNumberLastYear = obj.value
              }
              // 二、列表排序处理, 排序方法以后优化
              // 1.筛选
              const filterArr = this.tableAllData.filter(item => {
                return item.formats1Code === this.currentRankData.formats1Code
              })
              // 2.根据排名数，进行1次排序
              const sortArr1 = filterArr.sort(this.sortYtSalesAmountRank)
              // 3.根据指标值，进行2次排序
              const sortArr2 = this.sortSecond(sortArr1)
              // 4.赋值展示
              this.tableData = sortArr2.map((item) => {
                return {
                  storeName: item.storeName,
                  isMark: item.isMark,
                  formatsName: item.formatsName,
                  floorName: item.floorName,
                  indicator: item.salesAmount,
                  rankIndex: item.ytSalesAmountRank,
                  storeCode: item.storeCode,
                  validFlag: item.validFlag
                }
              })
              this.showContrast()
            } else {
              this.showRankData = false
            }
          } else if (this.indicatorB === '全部业态' && this.indicatorC === '全部楼层') {
            if (this.currentRankData) {
              this.showRankData = true
              // 一、数值处理
              // 1.获取当前排名
              this.rankNumber = this.currentRankData.salesAmountRank
              // 2.获取对比上个周期
              if (this.LastRankData) {
                const obj = this.compare(this.currentRankData.salesAmountRank, this.LastRankData.salesAmountRank) // 用现在的排名减过去的排名
                this.isChangeRank = obj.type
                this.rankNumberLast = obj.value
              }
              // 3.获取对比上年年周期
              if (this.lastYearRank) {
                const obj = this.compare(this.currentRankData.salesAmountRank, this.lastYearRank.salesAmountRank)
                this.isChangeRankYear = obj.type
                this.rankNumberLastYear = obj.value
              }
              // 二、列表排序处理, 排序方法以后优化
              // 1.筛选
              const filterArr = this.tableAllData.filter(item => {
                return item
              })
              // 2.根据排名数，进行1次排序
              const sortArr1 = filterArr.sort(this.sortSalesAmountRank)
              // 3.根据指标值，进行2次排序
              const sortArr2 = this.sortSecond(sortArr1)
              // 4.赋值展示
              this.tableData = sortArr2.map((item) => {
                return {
                  storeName: item.storeName,
                  isMark: item.isMark,
                  formatsName: item.formatsName,
                  floorName: item.floorName,
                  indicator: item.salesAmount,
                  rankIndex: item.salesAmountRank,
                  storeCode: item.storeCode,
                  validFlag: item.validFlag
                }
              })
              this.showContrast()
            } else {
              this.showRankData = false
            }
          }
          // 二、指标值处理
          if (this.currentRankData) {
            this.showIndicatorData = true
            // 1.获取当前指标值
            this.indicatorData = (this.currentRankData.salesAmount / 10000).toFixed(2)
            // 2.获取对比第一名
            if (this.storeName === this.tableData[0].storeName) { // 如果第一名是本店铺，就跟第二名比
              if (this.tableData[1]) {
                let percent = ''
                if (this.currentRankData.salesAmount) {
                  percent = (((this.currentRankData.salesAmount - this.tableData[1].indicator) / this.currentRankData.salesAmount) * 100).toFixed(2)
                }
                const obj = this.compare(this.currentRankData.salesAmount, this.tableData[1].indicator)
                this.isChangeIndicator = obj.type
                if (percent) {
                  this.indicatorDataLast = (this.tableData[1].indicator / 10000).toFixed(2) + '万元' + ' ' + percent + '%'
                } else {
                  this.indicatorDataLast = ''
                }
                this.numberOneStoreName = JSON.parse(JSON.stringify(this.tableData[1].storeName)) // 获取排名第二的店铺名
              }
              this.reputation = '第二'
            } else { // 如果第一名不是本店铺，就跟第一名比
              let percent = ''
              if (this.tableData[0].indicator) {
                percent = (((this.tableData[0].indicator - this.currentRankData.salesAmount) / this.tableData[0].indicator) * 100).toFixed(2)
              }
              const obj = this.compare(this.currentRankData.salesAmount, this.tableData[0].indicator)
              this.isChangeIndicator = obj.type
              if (percent) {
                this.indicatorDataLast = (this.tableData[0].indicator / 10000).toFixed(2) + '万元' + ' ' + percent + '%'
              } else {
                this.indicatorDataLast = ''
              }
              // 获取排名第一的店铺名
              this.reputation = '第一'
              this.numberOneStoreName = JSON.parse(JSON.stringify(this.tableData[0].storeName))
            }
            // 3.对比平均值
            let sum = 0
            for (let i = 0; i < this.tableData.length; i++) {
              sum = sum + this.tableData[i].indicator
            }
            const average = sum / this.tableData.length
            const object = this.compare(this.currentRankData.salesAmount, average)
            this.isChangeIndicatorYear = object.type
            if (average) {
              const percent = ((Math.abs(this.currentRankData.salesAmount - average) / average) * 100).toFixed(2)
              if (percent !== '0.00') {
                this.indicatorDataLastYear = (average / 10000).toFixed(2) + '万元' + ' ' + percent + '%'
              } else {
                this.indicatorDataLastYear = ''
              }
            } else {
              this.indicatorDataLastYear = ''
            }
          } else {
            this.showIndicatorData = false
          }
          break
        case '交易笔数':
          // 一、排名数据处理
          if (this.indicatorB === '同业态' && this.indicatorC === '同楼层') {
            if (this.currentRankData) {
              this.showRankData = true
              // 一、数值处理
              // 1.获取当前排名
              this.rankNumber = this.currentRankData.ytlcOrderCountRank
              // 2.获取对比上个周期
              if (this.LastRankData) {
                const obj = this.compare(this.currentRankData.ytlcOrderCountRank, this.LastRankData.ytlcOrderCountRank) // 用现在的排名减过去的排名
                this.isChangeRank = obj.type
                this.rankNumberLast = obj.value
              }
              // 3.获取对比上年年周期
              if (this.lastYearRank) {
                const obj = this.compare(this.currentRankData.ytlcOrderCountRank, this.lastYearRank.ytlcOrderCountRank)
                this.isChangeRankYear = obj.type
                this.rankNumberLastYear = obj.value
              }
              // 二、列表排序处理, 排序方法以后优化
              // 1.筛选
              const filterArr = this.tableAllData.filter(item => {
                return item.formats1Code === this.currentRankData.formats1Code && item.floorCode === this.currentRankData.floorCode
              })
              // 2.根据排名数，进行1次排序
              const sortArr1 = filterArr.sort(this.sortYtlcOrderCountRank)
              // 3.根据指标值，进行2次排序
              const sortArr2 = this.sortSecond(sortArr1)
              // 4.赋值展示
              this.tableData = sortArr2.map((item) => {
                return {
                  storeName: item.storeName,
                  isMark: item.isMark,
                  formatsName: item.formatsName,
                  floorName: item.floorName,
                  indicator: item.orderCount,
                  rankIndex: item.ytlcOrderCountRank,
                  storeCode: item.storeCode,
                  validFlag: item.validFlag
                }
              })
              this.showContrast()
            } else {
              this.showRankData = false
            }
          } else if (this.indicatorB === '全部业态' && this.indicatorC === '同楼层') {
            if (this.currentRankData) {
              this.showRankData = true
              // 一、数值处理
              // 1.获取当前排名
              this.rankNumber = this.currentRankData.lcOrderCountRank
              // 2.获取对比上个周期
              if (this.LastRankData) {
                const obj = this.compare(this.currentRankData.lcOrderCountRank, this.LastRankData.lcOrderCountRank) // 用现在的排名减过去的排名
                this.isChangeRank = obj.type
                this.rankNumberLast = obj.value
              }
              // 3.获取对比上年年周期
              if (this.lastYearRank) {
                const obj = this.compare(this.currentRankData.lcOrderCountRank, this.lastYearRank.lcOrderCountRank)
                this.isChangeRankYear = obj.type
                this.rankNumberLastYear = obj.value
              }
              // 二、列表排序处理, 排序方法以后优化
              // 1.筛选
              const filterArr = this.tableAllData.filter(item => {
                return item.floorCode === this.currentRankData.floorCode
              })
              // 2.根据排名数，进行1次排序
              const sortArr1 = filterArr.sort(this.sortLcOrderCountRank)
              // 3.根据指标值，进行2次排序
              const sortArr2 = this.sortSecond(sortArr1)
              // 4.赋值展示
              this.tableData = sortArr2.map((item) => {
                return {
                  storeName: item.storeName,
                  isMark: item.isMark,
                  formatsName: item.formatsName,
                  floorName: item.floorName,
                  indicator: item.orderCount,
                  rankIndex: item.lcOrderCountRank,
                  storeCode: item.storeCode,
                  validFlag: item.validFlag
                }
              })
              this.showContrast()
            } else {
              this.showRankData = false
            }
          } else if (this.indicatorB === '同业态' && this.indicatorC === '全部楼层') {
            if (this.currentRankData) {
              this.showRankData = true
              // 一、数值处理
              // 1.获取当前排名
              this.rankNumber = this.currentRankData.ytOrderCountRank
              // 2.获取对比上个周期
              if (this.LastRankData) {
                const obj = this.compare(this.currentRankData.ytOrderCountRank, this.LastRankData.ytOrderCountRank) // 用现在的排名减过去的排名
                this.isChangeRank = obj.type
                this.rankNumberLast = obj.value
              }
              // 3.获取对比上年年周期
              if (this.lastYearRank) {
                const obj = this.compare(this.currentRankData.ytOrderCountRank, this.lastYearRank.ytOrderCountRank)
                this.isChangeRankYear = obj.type
                this.rankNumberLastYear = obj.value
              }
              // 二、列表排序处理, 排序方法以后优化
              // 1.筛选
              const filterArr = this.tableAllData.filter(item => {
                return item.formats1Code === this.currentRankData.formats1Code
              })
              // 2.根据排名数，进行1次排序
              const sortArr1 = filterArr.sort(this.sortytOrderCountRank)
              // 3.根据指标值，进行2次排序
              const sortArr2 = this.sortSecond(sortArr1)
              // 4.赋值展示
              this.tableData = sortArr2.map((item) => {
                return {
                  storeName: item.storeName,
                  isMark: item.isMark,
                  formatsName: item.formatsName,
                  floorName: item.floorName,
                  indicator: item.orderCount,
                  rankIndex: item.ytOrderCountRank,
                  storeCode: item.storeCode,
                  validFlag: item.validFlag
                }
              })
              this.showContrast()
            } else {
              this.showRankData = false
            }
          } else if (this.indicatorB === '全部业态' && this.indicatorC === '全部楼层') {
            if (this.currentRankData) {
              this.showRankData = true
              // 一、数值处理
              // 1.获取当前排名
              this.rankNumber = this.currentRankData.orderCountRank
              // 2.获取对比上个周期
              if (this.LastRankData) {
                const obj = this.compare(this.currentRankData.orderCountRank, this.LastRankData.orderCountRank) // 用现在的排名减过去的排名
                this.isChangeRank = obj.type
                this.rankNumberLast = obj.value
              }
              // 3.获取对比上年年周期
              if (this.lastYearRank) {
                const obj = this.compare(this.currentRankData.orderCountRank, this.lastYearRank.orderCountRank)
                this.isChangeRankYear = obj.type
                this.rankNumberLastYear = obj.value
              }
              // 二、列表排序处理, 排序方法以后优化
              // 1.筛选
              const filterArr = this.tableAllData.filter(item => {
                return item
              })
              // 2.根据排名数，进行1次排序
              const sortArr1 = filterArr.sort(this.sortOrderCountRank)
              // 3.根据指标值，进行2次排序
              const sortArr2 = this.sortSecond(sortArr1)
              // 4.赋值展示
              this.tableData = sortArr2.map((item) => {
                return {
                  storeName: item.storeName,
                  isMark: item.isMark,
                  formatsName: item.formatsName,
                  floorName: item.floorName,
                  indicator: item.orderCount,
                  rankIndex: item.orderCountRank,
                  storeCode: item.storeCode,
                  validFlag: item.validFlag
                }
              })
              this.showContrast()
            } else {
              this.showRankData = false
            }
          }
          // 二、指标值处理
          if (this.currentRankData) {
            this.showIndicatorData = true
            // 1.获取当前指标值
            this.indicatorData = this.currentRankData.orderCount
            // 2.获取对比第一名
            if (this.storeName === this.tableData[0].storeName) { // 如果第一名是本店铺，就跟第二名比
              if (this.tableData[1]) {
                let percent = ''
                if (this.currentRankData.orderCount) {
                  percent = (((this.currentRankData.orderCount - this.tableData[1].indicator) / this.currentRankData.orderCount) * 100).toFixed(2)
                }
                const obj = this.compare(this.currentRankData.orderCount, this.tableData[1].indicator)
                this.isChangeIndicator = obj.type
                if (percent) {
                  this.indicatorDataLast = this.tableData[1].indicator + '单' + ' ' + percent + '%'
                } else {
                  this.indicatorDataLast = ''
                }
                this.numberOneStoreName = JSON.parse(JSON.stringify(this.tableData[1].storeName)) // 获取排名第二的店铺名
              }
              this.reputation = '第二'
            } else { // 如果第一名不是本店铺，就跟第一名比
              let percent = ''
              if (this.tableData[0].indicator) {
                percent = (((this.tableData[0].indicator - this.currentRankData.orderCount) / this.tableData[0].indicator) * 100).toFixed(2)
              }
              const obj = this.compare(this.currentRankData.orderCount, this.tableData[0].indicator)
              this.isChangeIndicator = obj.type
              if (percent) {
                this.indicatorDataLast = this.tableData[0].indicator + '单' + ' ' + percent + '%'
              } else {
                this.indicatorDataLast = ''
              }
              // 获取排名第一的店铺名
              this.reputation = '第一'
              this.numberOneStoreName = JSON.parse(JSON.stringify(this.tableData[0].storeName))
            }
            // 3.对比平均值
            let sum = 0
            for (let i = 0; i < this.tableData.length; i++) {
              sum = sum + this.tableData[i].indicator
            }
            const average = sum / this.tableData.length
            const object = this.compare(this.currentRankData.orderCount, average)
            this.isChangeIndicatorYear = object.type
            if (average) {
              const percent = ((Math.abs(this.currentRankData.orderCount - average) / average) * 100).toFixed(2)
              if (percent !== '0.00') {
                this.indicatorDataLastYear = average.toFixed(2) + '单' + ' ' + percent + '%'
              } else {
                this.indicatorDataLastYear = ''
              }
            } else {
              this.indicatorDataLastYear = ''
            }
          } else {
            this.showIndicatorData = false
          }
          break
        case '客单价':
          // 一、排名数据处理
          if (this.indicatorB === '同业态' && this.indicatorC === '同楼层') {
            if (this.currentRankData) {
              this.showRankData = true
              // 一、数值处理
              // 1.获取当前排名
              this.rankNumber = this.currentRankData.ytlcUnitPriceRank
              // 2.获取对比上个周期
              if (this.LastRankData) {
                const obj = this.compare(this.currentRankData.ytlcUnitPriceRank, this.LastRankData.ytlcUnitPriceRank) // 用现在的排名减过去的排名
                this.isChangeRank = obj.type
                this.rankNumberLast = obj.value
              }
              // 3.获取对比上年年周期
              if (this.lastYearRank) {
                const obj = this.compare(this.currentRankData.ytlcUnitPriceRank, this.lastYearRank.ytlcUnitPriceRank)
                this.isChangeRankYear = obj.type
                this.rankNumberLastYear = obj.value
              }
              // 二、列表排序处理, 排序方法以后优化
              // 1.筛选
              const filterArr = this.tableAllData.filter(item => {
                return item.formats1Code === this.currentRankData.formats1Code && item.floorCode === this.currentRankData.floorCode
              })
              // 2.根据排名数，进行1次排序
              const sortArr1 = filterArr.sort(this.sortYtlcUnitPriceRank)
              // 3.根据指标值，进行2次排序
              const sortArr2 = this.sortSecond(sortArr1)
              // 4.赋值展示
              this.tableData = sortArr2.map((item) => {
                return {
                  storeName: item.storeName,
                  isMark: item.isMark,
                  formatsName: item.formatsName,
                  floorName: item.floorName,
                  indicator: item.unitPrice,
                  rankIndex: item.ytlcUnitPriceRank,
                  storeCode: item.storeCode,
                  validFlag: item.validFlag
                }
              })
              this.showContrast()
            } else {
              this.showRankData = false
            }
          } else if (this.indicatorB === '全部业态' && this.indicatorC === '同楼层') {
            if (this.currentRankData) {
              this.showRankData = true
              // 一、数值处理
              // 1.获取当前排名
              this.rankNumber = this.currentRankData.lcUnitPriceRank
              // 2.获取对比上个周期
              if (this.LastRankData) {
                const obj = this.compare(this.currentRankData.lcUnitPriceRank, this.LastRankData.lcUnitPriceRank) // 用现在的排名减过去的排名
                this.isChangeRank = obj.type
                this.rankNumberLast = obj.value
              }
              // 3.获取对比上年年周期
              if (this.lastYearRank) {
                const obj = this.compare(this.currentRankData.lcUnitPriceRank, this.lastYearRank.lcUnitPriceRank)
                this.isChangeRankYear = obj.type
                this.rankNumberLastYear = obj.value
              }
              // 二、列表排序处理, 排序方法以后优化
              // 1.筛选
              const filterArr = this.tableAllData.filter(item => {
                return item.floorCode === this.currentRankData.floorCode
              })
              // 2.根据排名数，进行1次排序
              const sortArr1 = filterArr.sort(this.sortLcUnitPriceRank)
              // 3.根据指标值，进行2次排序
              const sortArr2 = this.sortSecond(sortArr1)
              // 4.赋值展示
              this.tableData = sortArr2.map((item) => {
                return {
                  storeName: item.storeName,
                  isMark: item.isMark,
                  formatsName: item.formatsName,
                  floorName: item.floorName,
                  indicator: item.unitPrice,
                  rankIndex: item.lcUnitPriceRank,
                  storeCode: item.storeCode,
                  validFlag: item.validFlag
                }
              })
              this.showContrast()
            } else {
              this.showRankData = false
            }
          } else if (this.indicatorB === '同业态' && this.indicatorC === '全部楼层') {
            if (this.currentRankData) {
              this.showRankData = true
              // 一、数值处理
              // 1.获取当前排名
              this.rankNumber = this.currentRankData.ytUnitPriceRank
              // 2.获取对比上个周期
              if (this.LastRankData) {
                const obj = this.compare(this.currentRankData.ytUnitPriceRank, this.LastRankData.ytUnitPriceRank) // 用现在的排名减过去的排名
                this.isChangeRank = obj.type
                this.rankNumberLast = obj.value
              }
              // 3.获取对比上年年周期
              if (this.lastYearRank) {
                const obj = this.compare(this.currentRankData.ytUnitPriceRank, this.lastYearRank.ytUnitPriceRank)
                this.isChangeRankYear = obj.type
                this.rankNumberLastYear = obj.value
              }
              // 二、列表排序处理, 排序方法以后优化
              // 1.筛选
              const filterArr = this.tableAllData.filter(item => {
                return item.formats1Code === this.currentRankData.formats1Code
              })
              // 2.根据排名数，进行1次排序
              const sortArr1 = filterArr.sort(this.sortYtUnitPriceRank)
              // 3.根据指标值，进行2次排序
              const sortArr2 = this.sortSecond(sortArr1)
              // 4.赋值展示
              this.tableData = sortArr2.map((item) => {
                return {
                  storeName: item.storeName,
                  isMark: item.isMark,
                  formatsName: item.formatsName,
                  floorName: item.floorName,
                  indicator: item.unitPrice,
                  rankIndex: item.ytUnitPriceRank,
                  storeCode: item.storeCode,
                  validFlag: item.validFlag
                }
              })
              this.showContrast()
            } else {
              this.showRankData = false
            }
          } else if (this.indicatorB === '全部业态' && this.indicatorC === '全部楼层') {
            if (this.currentRankData) {
              this.showRankData = true
              // 一、数值处理
              // 1.获取当前排名
              this.rankNumber = this.currentRankData.unitPriceRank
              // 2.获取对比上个周期
              if (this.LastRankData) {
                const obj = this.compare(this.currentRankData.unitPriceRank, this.LastRankData.unitPriceRank) // 用现在的排名减过去的排名
                this.isChangeRank = obj.type
                this.rankNumberLast = obj.value
              }
              // 3.获取对比上年年周期
              if (this.lastYearRank) {
                const obj = this.compare(this.currentRankData.unitPriceRank, this.lastYearRank.unitPriceRank)
                this.isChangeRankYear = obj.type
                this.rankNumberLastYear = obj.value
              }
              // 二、列表排序处理, 排序方法以后优化
              // 1.筛选
              const filterArr = this.tableAllData.filter(item => {
                return item
              })
              // 2.根据排名数，进行1次排序
              const sortArr1 = filterArr.sort(this.sortUnitPriceRank)
              // 3.根据指标值，进行2次排序
              const sortArr2 = this.sortSecond(sortArr1)
              // 4.赋值展示
              this.tableData = sortArr2.map((item) => {
                return {
                  storeName: item.storeName,
                  isMark: item.isMark,
                  formatsName: item.formatsName,
                  floorName: item.floorName,
                  indicator: item.unitPrice,
                  rankIndex: item.unitPriceRank,
                  storeCode: item.storeCode,
                  validFlag: item.validFlag
                }
              })
              this.showContrast()
            } else {
              this.showRankData = false
            }
          }
          // 二、指标值处理
          if (this.currentRankData) {
            this.showIndicatorData = true
            // 1.获取当前指标值
            this.indicatorData = this.currentRankData.unitPrice
            // 2.获取对比第一名
            if (this.storeName === this.tableData[0].storeName) { // 如果第一名是本店铺，就跟第二名比
              if (this.tableData[1]) {
                let percent = ''
                if (this.currentRankData.unitPrice) {
                  percent = (((this.currentRankData.unitPrice - this.tableData[1].indicator) / this.currentRankData.unitPrice) * 100).toFixed(2)
                }
                const obj = this.compare(this.currentRankData.unitPrice, this.tableData[1].indicator)
                this.isChangeIndicator = obj.type
                if (percent) {
                  this.indicatorDataLast = this.tableData[1].indicator + '元' + ' ' + percent + '%'
                } else {
                  this.indicatorDataLast = ''
                }
                this.numberOneStoreName = JSON.parse(JSON.stringify(this.tableData[1].storeName)) // 获取排名第二的店铺名
              }
              this.reputation = '第二'
            } else { // 如果第一名不是本店铺，就跟第一名比
              let percent = ''
              if (this.tableData[0].indicator) {
                percent = (((this.tableData[0].indicator - this.currentRankData.unitPrice) / this.tableData[0].indicator) * 100).toFixed(2)
              }
              const obj = this.compare(this.currentRankData.unitPrice, this.tableData[0].indicator)
              this.isChangeIndicator = obj.type
              if (percent) {
                this.indicatorDataLast = this.tableData[0].indicator + '元' + ' ' + percent + '%'
              } else {
                this.indicatorDataLast = ''
              }
              // 获取排名第一的店铺名
              this.reputation = '第一'
              this.numberOneStoreName = JSON.parse(JSON.stringify(this.tableData[0].storeName))
            }
            // 3.对比平均值
            let sum = 0
            for (let i = 0; i < this.tableData.length; i++) {
              sum = sum + this.tableData[i].indicator
            }
            const average = sum / this.tableData.length
            const object = this.compare(this.currentRankData.unitPrice, average)
            this.isChangeIndicatorYear = object.type
            if (average) {
              const percent = ((Math.abs(this.currentRankData.unitPrice - average) / average) * 100).toFixed(2)
              if (percent !== '0.00') {
                this.indicatorDataLastYear = average.toFixed(2) + '元' + ' ' + percent + '%'
              } else {
                this.indicatorDataLastYear = ''
              }
            } else {
              this.indicatorDataLastYear = ''
            }
          } else {
            this.showIndicatorData = false
          }
          break
        case '销售坪效':
          // 一、排名数据处理
          if (this.indicatorB === '同业态' && this.indicatorC === '同楼层') {
            if (this.currentRankData) {
              this.showRankData = true
              // 一、数值处理
              // 1.获取当前排名
              this.rankNumber = this.currentRankData.ytlcSalesEffectRank
              // 2.获取对比上个周期
              if (this.LastRankData) {
                const obj = this.compare(this.currentRankData.ytlcSalesEffectRank, this.LastRankData.ytlcSalesEffectRank) // 用现在的排名减过去的排名
                this.isChangeRank = obj.type
                this.rankNumberLast = obj.value
              }
              // 3.获取对比上年年周期
              if (this.lastYearRank) {
                const obj = this.compare(this.currentRankData.ytlcSalesEffectRank, this.lastYearRank.ytlcSalesEffectRank)
                this.isChangeRankYear = obj.type
                this.rankNumberLastYear = obj.value
              }
              // 二、列表排序处理, 排序方法以后优化
              // 1.筛选
              const filterArr = this.tableAllData.filter(item => {
                return item.formats1Code === this.currentRankData.formats1Code && item.floorCode === this.currentRankData.floorCode
              })
              // 2.根据排名数，进行1次排序
              const sortArr1 = filterArr.sort(this.sortYtlcSalesEffectRank)
              // 3.根据指标值，进行2次排序
              const sortArr2 = this.sortSecond(sortArr1)
              // 4.赋值展示
              this.tableData = sortArr2.map((item) => {
                return {
                  storeName: item.storeName,
                  isMark: item.isMark,
                  formatsName: item.formatsName,
                  floorName: item.floorName,
                  indicator: item.salesEffect,
                  rankIndex: item.ytlcSalesEffectRank,
                  storeCode: item.storeCode,
                  validFlag: item.validFlag
                }
              })
              this.showContrast()
            } else {
              this.showRankData = false
            }
          } else if (this.indicatorB === '全部业态' && this.indicatorC === '同楼层') {
            if (this.currentRankData) {
              this.showRankData = true
              // 一、数值处理
              // 1.获取当前排名
              this.rankNumber = this.currentRankData.lcSalesEffectRank
              // 2.获取对比上个周期
              if (this.LastRankData) {
                const obj = this.compare(this.currentRankData.lcSalesEffectRank, this.LastRankData.lcSalesEffectRank) // 用现在的排名减过去的排名
                this.isChangeRank = obj.type
                this.rankNumberLast = obj.value
              }
              // 3.获取对比上年年周期
              if (this.lastYearRank) {
                const obj = this.compare(this.currentRankData.lcSalesEffectRank, this.lastYearRank.lcSalesEffectRank)
                this.isChangeRankYear = obj.type
                this.rankNumberLastYear = obj.value
              }
              // 二、列表排序处理, 排序方法以后优化
              // 1.筛选
              const filterArr = this.tableAllData.filter(item => {
                return item.floorCode === this.currentRankData.floorCode
              })
              // 2.根据排名数，进行1次排序
              const sortArr1 = filterArr.sort(this.sortLcSalesEffectRank)
              // 3.根据指标值，进行2次排序
              const sortArr2 = this.sortSecond(sortArr1)
              // 4.赋值展示
              this.tableData = sortArr2.map((item) => {
                return {
                  storeName: item.storeName,
                  isMark: item.isMark,
                  formatsName: item.formatsName,
                  floorName: item.floorName,
                  indicator: item.salesEffect,
                  rankIndex: item.lcSalesEffectRank,
                  storeCode: item.storeCode,
                  validFlag: item.validFlag
                }
              })
              this.showContrast()
            } else {
              this.showRankData = false
            }
          } else if (this.indicatorB === '同业态' && this.indicatorC === '全部楼层') {
            if (this.currentRankData) {
              this.showRankData = true
              // 一、数值处理
              // 1.获取当前排名
              this.rankNumber = this.currentRankData.ytSalesEffectRank
              // 2.获取对比上个周期
              if (this.LastRankData) {
                const obj = this.compare(this.currentRankData.ytSalesEffectRank, this.LastRankData.ytSalesEffectRank) // 用现在的排名减过去的排名
                this.isChangeRank = obj.type
                this.rankNumberLast = obj.value
              }
              // 3.获取对比上年年周期
              if (this.lastYearRank) {
                const obj = this.compare(this.currentRankData.ytSalesEffectRank, this.lastYearRank.ytSalesEffectRank)
                this.isChangeRankYear = obj.type
                this.rankNumberLastYear = obj.value
              }
              // 二、列表排序处理, 排序方法以后优化
              // 1.筛选
              const filterArr = this.tableAllData.filter(item => {
                return item.formats1Code === this.currentRankData.formats1Code
              })
              // 2.根据排名数，进行1次排序
              const sortArr1 = filterArr.sort(this.sortYtSalesEffectRank)
              // 3.根据指标值，进行2次排序
              const sortArr2 = this.sortSecond(sortArr1)
              // 4.赋值展示
              this.tableData = sortArr2.map((item) => {
                return {
                  storeName: item.storeName,
                  isMark: item.isMark,
                  formatsName: item.formatsName,
                  floorName: item.floorName,
                  indicator: item.salesEffect,
                  rankIndex: item.ytSalesEffectRank,
                  storeCode: item.storeCode,
                  validFlag: item.validFlag
                }
              })
              this.showContrast()
            } else {
              this.showRankData = false
            }
          } else if (this.indicatorB === '全部业态' && this.indicatorC === '全部楼层') {
            if (this.currentRankData) {
              this.showRankData = true
              // 一、数值处理
              // 1.获取当前排名
              this.rankNumber = this.currentRankData.salesEffectRank
              // 2.获取对比上个周期
              if (this.LastRankData) {
                const obj = this.compare(this.currentRankData.salesEffectRank, this.LastRankData.salesEffectRank) // 用现在的排名减过去的排名
                this.isChangeRank = obj.type
                this.rankNumberLast = obj.value
              }
              // 3.获取对比上年年周期
              if (this.lastYearRank) {
                const obj = this.compare(this.currentRankData.salesEffectRank, this.lastYearRank.salesEffectRank)
                this.isChangeRankYear = obj.type
                this.rankNumberLastYear = obj.value
              }
              // 二、列表排序处理, 排序方法以后优化
              // 1.筛选
              const filterArr = this.tableAllData.filter(item => {
                return item
              })
              // 2.根据排名数，进行1次排序
              const sortArr1 = filterArr.sort(this.sortSalesEffectRank)
              // 3.根据指标值，进行2次排序
              const sortArr2 = this.sortSecond(sortArr1)
              // 4.赋值展示
              this.tableData = sortArr2.map((item) => {
                return {
                  storeName: item.storeName,
                  isMark: item.isMark,
                  formatsName: item.formatsName,
                  floorName: item.floorName,
                  indicator: item.salesEffect,
                  rankIndex: item.salesEffectRank,
                  storeCode: item.storeCode,
                  validFlag: item.validFlag
                }
              })
              this.showContrast()
            } else {
              this.showRankData = false
            }
          }
          // 二、指标值处理
          if (this.currentRankData) {
            this.showIndicatorData = true
            // 1.获取当前指标值
            this.indicatorData = this.currentRankData.salesEffect
            // 2.获取对比第一名
            if (this.storeName === this.tableData[0].storeName) { // 如果第一名是本店铺，就跟第二名比
              if (this.tableData[1]) {
                let percent = ''
                if (this.currentRankData.salesEffect) {
                  percent = (((this.currentRankData.salesEffect - this.tableData[1].indicator) / this.currentRankData.salesEffect) * 100).toFixed(2)
                }
                const obj = this.compare(this.currentRankData.salesEffect, this.tableData[1].indicator)
                this.isChangeIndicator = obj.type
                if (percent) {
                  this.indicatorDataLast = this.tableData[1].indicator + '元/日/㎡' + ' ' + percent + '%'
                } else {
                  this.indicatorDataLast = ''
                }
                this.numberOneStoreName = JSON.parse(JSON.stringify(this.tableData[1].storeName)) // 获取排名第二的店铺名
              }
              this.reputation = '第二'
            } else { // 如果第一名不是本店铺，就跟第一名比
              let percent = ''
              if (this.tableData[0].indicator) {
                percent = (((this.tableData[0].indicator - this.currentRankData.salesEffect) / this.tableData[0].indicator) * 100).toFixed(2)
              }
              const obj = this.compare(this.currentRankData.salesEffect, this.tableData[0].indicator)
              this.isChangeIndicator = obj.type
              if (percent) {
                this.indicatorDataLast = this.tableData[0].indicator + '元/日/㎡' + ' ' + percent + '%'
              } else {
                this.indicatorDataLast = ''
              }
              // 获取排名第一的店铺名
              this.reputation = '第一'
              this.numberOneStoreName = JSON.parse(JSON.stringify(this.tableData[0].storeName))
            }
            // 3.对比平均值
            let sum = 0
            for (let i = 0; i < this.tableData.length; i++) {
              sum = sum + this.tableData[i].indicator
            }
            const average = sum / this.tableData.length
            const object = this.compare(this.currentRankData.salesEffect, average)
            this.isChangeIndicatorYear = object.type
            if (average) {
              const percent = ((Math.abs(this.currentRankData.salesEffect - average) / average) * 100).toFixed(2)
              if (percent !== '0.00') {
                this.indicatorDataLastYear = average.toFixed(2) + '元/日/㎡' + ' ' + percent + '%'
              } else {
                this.indicatorDataLastYear = ''
              }
            } else {
              this.indicatorDataLastYear = ''
            }
          } else {
            this.showIndicatorData = false
          }
          break
      }
    },
    // 跳转到基础数据页面
    toBaseClick (item) {
      if (item.validFlag === 1) {
        this.$router.push({
          name: 'earlywarningAnalysis',
          query: Object.assign({ projectId: this.filtroProject, storeCode: item.storeCode }, { _: +new Date() })
        })
      }
    },
    // 排名和周期的绿色折线图，写死的，仅占位用，无数据或其他交互
    getChart () {
      this.charts1 = echarts.init(document.getElementById('charts1'))
      this.charts2 = echarts.init(document.getElementById('charts2'))
      this.charts1.setOption(this.chartsOptionLine())
      this.charts2.setOption(this.chartsOptionLine())
      const obj = {
        series: [
          {
            symbol: 'none',
            type: 'line',
            smooth: true,
            emphasis: {
              focus: 'series'
            },
            lineStyle: {
              color: '#7FBA7A'
            },
            areaStyle: {
              opacity: 1,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: 'rgba(127, 186, 122, .4)'
              }, {
                offset: 1,
                color: 'rgba(127, 186, 122, 0)'
              }])
            },
            data: [120, 230, 101, 434, 40, 430, 110]
          }
        ]
      }
      this.charts1.setOption(obj)
      this.charts2.setOption(obj)
      this.$nextTick(() => {
        window.addEventListener('resize', () => {
          this.charts1.resize()
          this.charts2.resize()
        })
      })
    },
    chartsOptionLine () {
      const option = {
        grid: {
          top: '0%',
          left: '0%',
          right: '0%',
          bottom: '0%',
          containLabel: false
        },
        legend: false,
        xAxis: {
          boundaryGap: false,
          axisLine: {
            show: false
          },
          type: 'category',
          axisTick: {
            show: false
          },
          splitLine: {
            show: false
          }
        },
        yAxis: [{
          axisLine: {
            show: false
          },
          type: 'value',
          axisTick: {
            show: false
          },
          splitLine: {
            show: false
          }
        }],
        series: []
      }
      return option
    }
  }
}
</script>

<style lang="stylus" scoped>
.view1
  padding-top 60px
  background #272930
  opacity 1
  border-radius 0 0 2px 2px
  .selectBtn-view
    margin-top 17px
    padding-left 24px
    line-height 33px
    display flex
    flex-flow wrap
    .type-item
      margin-top 15px
      display flex
      font-size 14px
      font-family PingFang SC
      color #B2B3BD
      .type-item-btn
        padding 0 3px
        height 33px
        border 1px solid #373A43
        opacity 1
        border-radius 2px
        span
          display inline-block
          width 77px
          height 27px
          line-height 27px
          font-size 14px
          font-family PingFang SC
          font-weight 500
          color rgba(255, 255, 255, 0.5)
          text-align center
          margin-right -1px
          cursor pointer
          &.active
            background #FFA134
            color #fff
            border-color #FFA134
        span:first-child
          border-radius 2px 0px 0px 2px
        span:last-child
          border-radius 0px 2px 2px 0px
      .type-item-select
        background-color #373A43
        .segmentation
          color #424752
        span
          color #B2B3BD
        & >>> .el-select
          width 112px
        & >>> .el-date-editor
          width 120px
          // width 144px
          input
            text-align center
  .tableChart
    display flex
    justify-content space-between
    margin-top 65px
    .w-table
      margin-left 24px
      margin-right 50px
      width 65%
      height 520px
      overflow-y auto
      table
        width 100%
        thead
          tr
            height 32px
            color #B2B3BD
            font-size 14px
            th
              border-bottom 1px solid #373A43
              text-align left
              vertical-align top
              &:first-child
                padding-right 25px
                text-align center
        tbody
          tr
            height 50px
            color #808191
            font-size 14px
            &:hover td
              background-color #373A43
            &:hover .progress-bg
              background-color #242731
            td
              border none
              border-bottom 1px solid #373A43
              text-align left
              &:first-child
                text-align center
                padding-right 25px
                img
                  width 20px
              &:nth-child(5)
                color #fff
              &:nth-child(6)
                padding-right 9.36%
          .mark
            background-color #FFF2F2
            color #262B30 !important
            &:hover td
              background-color #FFF2F2
            &:hover .progress-bg
              background-color #242731
    .chartView
      margin-right 24px
      width 35%
      .rank
        border 1px solid #373A43
        opacity 1
        border-radius 2px
        font-size 16px
        font-weight 400
        color #808191
      .rent
        margin-top 24px
        margin-bottom 24px
        border 1px solid #373A43
        opacity 1
        border-radius 2px
        font-size 16px
        font-weight 400
        color #808191
      .title
        margin 24px auto 38px 12px
        span
          vertical-align middle
          font-size 16px
          font-family PingFang SC
          font-weight 400
          color #FFF
          &:nth-child(3)
            color #808191
        img
          vertical-align middle
          margin-right 4px
          width 16px
          height 16px
      .chart
        margin-bottom 50px
        height 60px
        line-height 60px
        display flex
        justify-content space-between
        .number
          margin-left 32px
          font-size 60px
          font-family Bahnschrift
          font-weight bold
          color #FFFFFF
          opacity 1
        .chart-box
          margin 12px 24px auto 10px
          width 40%
          height 48px
      .comparison
        font-size 14px
        margin auto auto 12px 32px
        span
          font-size 16px
          color #fff
        .icon-data-down:before
          font-size 12px
          color #89DF81
        .icon-data-up:before
          font-size 12px
          color #FF754C
      .no-rank-data
        text-align center
        p
          margin-bottom 45px
          font-size 14px
          font-family PingFang SC
          font-weight 400
          line-height 41px
          color #808191
          opacity 1
.mr-70
  margin-right 70px
// elm-ui样式
// 1.下拉框
/deep/ .el-input__inner
  height 33px
  background-color #373A43
  color #808191
  border none
  border-radius 2px
  padding 0 18px
// 2.日期组件样式
// （1）日期icon
/deep/ .el-input__prefix
  display none
/deep/ .el-date-editor .el-input__suffix
  display none
// （2）输入框禁止点击时
/deep/ .is-disabled .el-input__inner
  color #808191 !important
  background-color #373a43 !important
</style>
