<template>
  <div>
    <div class="view1">
      <div class="selectBtn-view">
        <div class="type-item mr-70">
          对比指标：
          <div class="type-item-btn">
            <span
              @click="setIndicator('销售额')"
              :class="[indicator === '销售额' ? 'active': '']"
            >销售额</span>
            <span
              @click="setIndicator('交易笔数')"
              :class="[indicator === '交易笔数' ? 'active': '']"
            >交易笔数</span>
            <span
              @click="setIndicator('客单价')"
              :class="[indicator === '客单价' ? 'active': '']"
            >客单价</span>
            <span
              @click="setIndicator('销售坪效')"
              :class="[indicator === '销售坪效' ? 'active': '']"
            >销售坪效</span>
          </div>
        </div>
        <div class="type-item mr-70">
          时间范围1：
          <div class="type-item-select">
            <el-date-picker
              popper-class="orange-el-date-picker"
              v-model="startTimeA"
              type="datetime"
              placeholder="选择日期"
              value-format="yyyy-MM-dd HH:mm:ss"
              @change="setStartTimeA()"
              :picker-options="pickerOptions_startA"
            >
            </el-date-picker>
            <span>~</span>
            <el-date-picker
              popper-class="orange-el-date-picker"
              v-model="endTimeA"
              type="datetime"
              placeholder="选择日期"
              value-format="yyyy-MM-dd HH:mm:ss"
              @change="setEndTimeA()"
              :picker-options="pickerOptions_endA"
            >
            </el-date-picker>
          </div>
          <span class="VS">VS</span>
          时间范围2：
          <div class="type-item-select">
            <el-date-picker
              popper-class="orange-el-date-picker"
              v-model="startTimeB"
              type="datetime"
              placeholder="选择日期"
              value-format="yyyy-MM-dd HH:mm:ss"
              @change="setStartTimeB()"
              :picker-options="pickerOptions_startB"
            >
            </el-date-picker>
            <span>至</span>
            <el-date-picker
              popper-class="orange-el-date-picker"
              v-model="endTimeB"
              type="datetime"
              placeholder="选择日期"
              value-format="yyyy-MM-dd HH:mm:ss"
              @change="setEndTimeB()"
              :picker-options="pickerOptions_endB"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="type-item">
          <div class="type-item-select">
            <el-select
              popper-class="w-block-select-down yellow-select-panel"
              v-model="timeGranularity"
              size="small"
              placeholder="请选择"
              @change="handleData"
            >
              <el-option
                v-for="item in timeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="contrast">
        <div
          class="contrast-chart"
          id="contrast-chart"
        >
          <div class="contrast-checkBox">
            <span class="contrast-title">时段销售对比</span>
            <div class="checkBox fr">
              <el-checkbox
                @change="selectChart(0)"
                v-model="detailedContrast"
              >明细对比</el-checkbox>
              <el-checkbox
                @change="selectChart(1)"
                v-model="totalComparison"
              >总计对比</el-checkbox>
            </div>
          </div>
          <!-- 明细对比图 -->
          <div
            v-show="detailedContrast"
            id="singleCharts"
            class="charts-box"
          ></div>
          <!-- 总计对比 -->
          <div
            v-show="totalComparison"
            id="totalCharts"
            class="charts-box"
          ></div>
        </div>
        <div class="contrast-data">
          <span class="contrast-title">支付渠道对比</span>
          <ul class="fr">
            <li class="fl">
              <span></span>
              <!-- {{startTimeA + '~' + endTimeA}} -->
              时间范围1
            </li>
            <li class="fl">
              <span></span>
              <!-- {{startTimeB + '~' + endTimeB}} -->
              时间范围2
            </li>
          </ul>
          <div
            class="contrast-content"
            v-for="(item,index) in contrastList"
            :key="index"
          >
            <div class="contrast-content-title">
              <span>{{item.percent1}}</span>
              <span>{{item.name}}</span>
              <span>{{item.percent2}}</span>
            </div>
            <div class="doubleProgress">
              <div class="progress-bg">
                <div
                  :style="{
                    'width': item.progress1,
                  }"
                  class="progress-l"
                >
                </div>
              </div>
              <div class="progress-bg">
                <div
                  :style="{
                    'width': item.progress2,
                  }"
                  class="progress-r"
                >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="view2">
      <span class="contrast-title">销售货品对比</span>
      <!-- <span
        class="exportOut fr"
        @click="exportOutFunc()"
      >导出数据</span>
      <div class="clear"></div> -->
      <div
        class="w-table"
        :class="showTableData?'w-singed-table':'grey-singed-table'"
        @scroll="loadMore($event)"
      >
        <table>
          <thead>
            <tr>
              <th colspan="4">
                {{startTimeA}}
                <span>~</span>
                {{endTimeA}}
              </th>
            </tr>
            <tr>
              <th width="132">排名</th>
              <th width="178">货品名称</th>
              <th width="210">销售数量</th>
              <th width="283">销售金额(元)</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in tableDataA"
              :key="index"
              :class="item.makeUp?'greyTD':''"
            >
              <td>{{index + 1}}</td>
              <td>{{item.goodsName}}</td>
              <td>{{item.goodsNumber}}</td>
              <td>{{item.goodsAmount}}</td>
            </tr>
          </tbody>
        </table>
        <table>
          <thead>
            <tr>
              <th colspan="4">
                {{startTimeB}}
                <span>~</span>
                {{endTimeB}}
              </th>
            </tr>
            <tr>
              <th width="132">排名</th>
              <th width="178">货品名称</th>
              <th width="210">销售数量</th>
              <th width="283">销售金额(元)</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in tableDataB"
              :key="index"
              :class="item.makeUp?'greyTD':''"
            >
              <td>{{index + 1}}</td>
              <td>{{item.goodsName}}</td>
              <td>{{item.goodsNumber}}</td>
              <td>{{item.goodsAmount}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import echarts from 'echarts'
import { dateFormat } from '@/utils/index'
export default {
  name: 'timeContrast',
  data () {
    return {
      indicator: '销售额',
      contrastList: [{ // 进度条数据
        name: '微信支付占比',
        percent1: '-%', // 本店铺(显示数值用)
        progress1: '0%', // 本店铺(设置进度条宽度用)
        percent2: '-%', // 对比店铺(显示数值用)
        progress2: '0%' // 对比店铺(设置进度条宽度用)
      }, {
        name: '支付宝占比',
        percent1: '-%',
        progress1: '0%',
        percent2: '-%',
        progress2: '0%'
      }, {
        name: '现金占比',
        percent1: '-%',
        progress1: '0%',
        percent2: '-%',
        progress2: '0%'
      }, {
        name: '银行卡占比',
        percent1: '-%',
        progress1: '0%',
        percent2: '-%',
        progress2: '0%'
      }, {
        name: '储蓄卡占比',
        percent1: '-%',
        progress1: '0%',
        percent2: '-%',
        progress2: '0%'
      }, {
        name: '优惠券占比',
        percent1: '-%',
        progress1: '0%',
        percent2: '-%',
        progress2: '0%'
      }],
      singleCharts: {},
      totalCharts: {},
      labels: [], // 下标(日期)
      compareLabels: [], // 对比下标(日期)
      salesAmounts: '', // 销售金额
      compareSalesAmounts: '', //  对比销售金额
      orderCounts: '', // 销售笔数
      compareOrderCounts: '', //  对比销售笔数
      unitPrice: '', // 客单价
      compareUnitPrice: '', //  对比客单价
      salesEffects: '', // 销售坪效
      compareSalesEffects: '', //  对比销售坪效
      sumLabels: '', // 横坐标(总计对比图)
      sumSalesAmounts: '', // 销售金额(总计对比图)
      sumSalesEffects: '', // 销售坪效(总计对比图)
      sumUnitPrice: '', // 客单价(总计对比图)
      sumOrderCounts: '', // 交易笔数(总计对比图)
      tableDataA: [],
      showTableData: false,
      tableDataB: [],
      timeGranularity: '60分钟颗粒度',
      timeOptions: [{
        value: '60分钟颗粒度',
        label: '60分钟颗粒度'
      }, {
        value: '30分钟颗粒度',
        label: '30分钟颗粒度'
      }],
      startTimeA: '',
      endTimeA: '',
      startTimeB: '',
      endTimeB: '',
      pickerOptions_startA: {
        disabledDate: (time) => {
          if (this.endTimeaA) {
            return time.getTime() > Date.now() || time.getTime() > this.endTimeA
          } else {
            return time.getTime() > Date.now()
          }
        }
      },
      pickerOptions_endA: {
        disabledDate: (time) => {
          return time.getTime() < this.startTimeA || time.getTime() > Date.now()
        }
      },
      pickerOptions_startB: {
        disabledDate: (time2) => {
          if (this.endTimeB) {
            return time2.getTime() > Date.now() || time2.getTime() > this.endTimeB
          } else {
            return time2.getTime() > Date.now()
          }
        }
      },
      pickerOptions_endB: {
        disabledDate: (time2) => {
          return time2.getTime() < this.startTimeB || time2.getTime() > Date.now()
        }
      },
      isSameFloor: false,
      isSameIndustry: false,
      isShowSingleChart: true, // 明细对比图图
      isShowTotalChart: true, // 总计对比图
      currentPage: 1,
      detailedContrast: true,
      totalComparison: false
    }
  },
  props: {
    filtroProject: {
      type: Number
    },
    filtroStore: {
      type: String
    }
  },
  watch: {
    filtroStore (newValue, oldValue) {
      this.getData()
    }
  },
  mounted () {
    this.setDefaultDate()
    this.setCurrentTime()
    this.getData()
  },
  methods: {
    // 省缺时显示的数据
    setDefaultDate () {
      this.tableDataA = []
      this.tableDataB = []
      this.showTableData = false
      for (let i = 0; i < 5; i++) {
        const obj = {
          goodsName: '-',
          goodsNumber: '-',
          goodsAmount: '-',
          makeUp: true
        }
        this.tableDataA.push(obj)
        this.tableDataB.push(obj)
      }
    },
    // 生成时间范围数组
    getEveryDayDateByBetweenDate (start, end) {
      const startTime = new Date(start).getTime()
      const endTime = new Date(end).getTime()
      const dateList = []
      let interval = ''
      if (this.timeGranularity === '60分钟颗粒度') {
        interval = 3600 * 1000 * 1 // 60分钟颗粒度
      } else {
        interval = 3600 * 1000 * 0.5 // 30分钟颗粒度
      }
      for (let i = startTime; i <= endTime; i += interval) {
        const time = dateFormat('yyyy-MM-dd hh:mm:ss', new Date(i))
        dateList.push(time)
      }
      // console.log('dateList', dateList)
      return dateList
    },
    // 折线图缺省数据
    setDefaultLine () {
      this.labels = []
      this.salesAmounts = []
      this.orderCounts = []
      this.unitPrice = []
      this.salesEffects = []
      this.compareLabels = []
      this.compareSalesAmounts = []
      this.compareOrderCounts = []
      this.compareUnitPrice = []
      this.compareSalesEffects = []
      const timeA = this.getEveryDayDateByBetweenDate(this.startTimeA, this.endTimeA)
      for (let i = 0; i < timeA.length; i++) {
        this.labels.push(timeA[i])
        this.salesAmounts.push('')
        this.orderCounts.push('')
        this.unitPrice.push('')
        this.salesEffects.push('')
      }
      const timeB = this.getEveryDayDateByBetweenDate(this.startTimeB, this.endTimeB)
      for (let i = 0; i < timeB.length; i++) {
        this.compareLabels.push(timeB[i])
        this.compareSalesAmounts.push('')
        this.compareOrderCounts.push('')
        this.compareUnitPrice.push('')
        this.compareSalesEffects.push('')
      }
    },
    // 柱状图缺省数据
    setDefaultHistogram () {
      const timeLimit1 = this.startTimeA.replace(/-/g, '/') + '~' + this.endTimeA.replace(/-/g, '/')
      const timeLimit2 = this.startTimeB.replace(/-/g, '/') + '~' + this.endTimeB.replace(/-/g, '/')
      this.sumLabels = [timeLimit1, timeLimit2]
      this.sumSalesAmounts = ['', '']
      this.sumSalesEffects = ['', '']
      this.sumOrderCounts = ['', '']
    },
    selectChart (val) {
      if (val === 0) {
        this.detailedContrast = true
        this.totalComparison = false
      } else if (val === 1) {
        this.detailedContrast = false
        this.totalComparison = true
      }
      this.changeChart()
    },
    // 设置时间初始值
    setCurrentTime () {
      // 设置时间范围1默认值
      const endA = new Date()
      const startA = new Date()
      endA.setTime(endA.getTime() - 3600 * 1000 * 24 * 1)
      this.endTimeA = dateFormat('yyyy-MM-dd', endA) + ' ' + '08:00:00'
      startA.setTime(startA.getTime() - 3600 * 1000 * 24 * 7)
      this.startTimeA = dateFormat('yyyy-MM-dd', startA) + ' ' + '08:00:00'
      // 设置时间范围2默认值
      const endB = new Date()
      const startB = new Date()
      endB.setTime(endB.getTime() - 3600 * 1000 * 24 * 8)
      this.endTimeB = dateFormat('yyyy-MM-dd', endB) + ' ' + '08:00:00'
      startB.setTime(startB.getTime() - 3600 * 1000 * 24 * 14)
      this.startTimeB = dateFormat('yyyy-MM-dd', startB) + ' ' + '08:00:00'
    },
    // 获取数据
    getData () {
      // 重置数据
      this.currentPage = 1
      this.labels = []
      this.compareLabels = []
      this.salesAmounts = ''
      this.compareSalesAmounts = ''
      this.orderCounts = ''
      this.compareOrderCounts = ''
      this.unitPrice = ''
      this.compareUnitPrice = ''
      this.salesEffects = ''
      this.compareSalesEffects = ''
      this.sumLabels = ''
      this.sumSalesAmounts = ''
      this.sumSalesEffects = ''
      this.sumUnitPrice = ''
      this.sumOrderCounts = ''
      this.tableDataA = []
      this.tableDataB = []
      const params = {
        projectId: this.filtroProject,
        storeCode: this.filtroStore,
        hourType: this.timeGranularity === '60分钟颗粒度' ? 1 : 2,
        salesStartDate: this.startTimeA,
        salesEndDate: this.endTimeA,
        compareSalesStartDate: this.startTimeB,
        compareSalesEndDate: this.endTimeB
      }
      this.axios.get(api.getStoreDateRangeSaleCompare, { params: params }).then(res => {
        if (res.data.code === 0) {
          this.isShowSingleChart = true
          this.isShowTotalChart = true
          const resData = res.data.data
          // 明细对比图图表数据
          this.labels = resData.labels
          this.compareLabels = resData.compareLabels
          this.salesAmounts = resData.salesAmounts.map(item => {
            return (item / 10000).toFixed(2) // 单位转换，元转万元
          })
          this.compareSalesAmounts = resData.compareSalesAmounts.map(item => {
            return (item / 10000).toFixed(2) // 单位转换，元转万元
          })
          this.orderCounts = resData.orderCounts
          this.compareOrderCounts = resData.compareOrderCounts
          this.unitPrice = resData.unitPrice
          this.compareUnitPrice = resData.compareUnitPrice
          this.salesEffects = resData.salesEffects
          this.compareSalesEffects = resData.compareSalesEffects
          // 总计对比图表数据
          this.sumSalesAmounts = resData.sumSalesAmounts.map(item => {
            return (item / 10000).toFixed(2) // 单位转换，元转万元
          })
          this.sumSalesEffects = resData.sumSalesEffects
          this.sumOrderCounts = resData.sumOrderCounts
          this.sumUnitPrice = resData.sumUnitPrice
          this.sumLabels = resData.sumLabels
          // 支付渠道对比
          if (resData.salesTenderRadioVo) {
            this.contrastList[0].percent1 = resData.salesTenderRadioVo.salesWxRadio // 微信
            this.contrastList[0].progress1 = resData.salesTenderRadioVo.salesWxRadio
            this.contrastList[1].percent1 = resData.salesTenderRadioVo.salesZfbRadio // 支付宝
            this.contrastList[1].progress1 = resData.salesTenderRadioVo.salesZfbRadio
            this.contrastList[2].percent1 = resData.salesTenderRadioVo.salesXjRadio // 现金占比
            this.contrastList[2].progress1 = resData.salesTenderRadioVo.salesXjRadio
            this.contrastList[3].percent1 = resData.salesTenderRadioVo.salesYhkRadio // 银行卡占比
            this.contrastList[3].progress1 = resData.salesTenderRadioVo.salesYhkRadio
            this.contrastList[4].percent1 = resData.salesTenderRadioVo.salesCzkRadio // 储蓄卡占比
            this.contrastList[4].progress1 = resData.salesTenderRadioVo.salesCzkRadio
            this.contrastList[5].percent1 = resData.salesTenderRadioVo.salesYhqRadio // 优惠券占比
            this.contrastList[5].progress1 = resData.salesTenderRadioVo.salesYhqRadio
          } else {
            for (let i = 0; i < this.contrastList.length; i++) {
              this.contrastList[i].percent1 = '-%'
              this.contrastList[i].progress1 = '0%'
            }
          }
          if (resData.compareSalesTenderRadioVo) {
            this.contrastList[0].percent2 = resData.compareSalesTenderRadioVo.salesWxRadio // 微信
            this.contrastList[0].progress2 = resData.compareSalesTenderRadioVo.salesWxRadio
            this.contrastList[1].percent2 = resData.compareSalesTenderRadioVo.salesZfbRadio // 支付宝
            this.contrastList[1].progress2 = resData.compareSalesTenderRadioVo.salesZfbRadio
            this.contrastList[2].percent2 = resData.compareSalesTenderRadioVo.salesXjRadio // 现金占比
            this.contrastList[2].progress2 = resData.compareSalesTenderRadioVo.salesXjRadio
            this.contrastList[3].percent2 = resData.compareSalesTenderRadioVo.salesYhkRadio // 银行卡占比
            this.contrastList[3].progress2 = resData.compareSalesTenderRadioVo.salesYhkRadio
            this.contrastList[4].percent2 = resData.compareSalesTenderRadioVo.salesCzkRadio // 储蓄卡占比
            this.contrastList[4].progress2 = resData.compareSalesTenderRadioVo.salesCzkRadio
            this.contrastList[5].percent2 = resData.compareSalesTenderRadioVo.salesYhqRadio // 优惠券占比
            this.contrastList[5].progress2 = resData.compareSalesTenderRadioVo.salesYhqRadio
          } else {
            for (let i = 0; i < this.contrastList.length; i++) {
              this.contrastList[i].percent2 = '-%'
              this.contrastList[i].progress2 = '0%'
            }
          }
          this.changeChart()
          // this.getStoreSalesGoodsPageList()
        } else {
          this.isShowSingleChart = false
          this.isShowTotalChart = false
          this.setDefaultLine()
          this.setDefaultHistogram()
          this.changeChart() // 展示图表
        }
      })
      this.getStoreSalesGoodsPageList()
    },
    setIndicator (val) {
      this.indicator = val
      this.changeChart()
    },
    showSingleChartData () {
      switch (this.indicator) {
        case '销售额':
          // 图表数据处理
          if ((this.salesAmounts && this.salesAmounts.length > 0) || (this.compareSalesAmounts && this.compareSalesAmounts.length > 0)) {
            this.isShowSingleChart = true
            const obj = {
              indicator: '销售额', // y轴名
              unit: '万元', // y轴名单位
              time1: '', // 时间范围1
              time2: '', // 时间范围2
              label1: [], // 横坐标
              label2: [], // 横坐标
              value1: [], // 第一条线的值
              value2: [], // 第二条线的值
              xAxisIndexValue1: 0, // 设置主轴
              xAxisIndexValue2: 1 // 设置主轴
            }
            // 调整X轴长度和顺序
            const coordinate1 = this.salesAmounts || [] // 坐标值
            const coordinate2 = this.compareSalesAmounts || [] // 坐标值
            const labelsArr = JSON.parse(JSON.stringify(this.labels)) // X轴名
            const compareLabelsArr = JSON.parse(JSON.stringify(this.compareLabels)) // X轴名
            const difference = labelsArr.length - compareLabelsArr.length // 根据差值，补空，使两个X轴坐标数组长度一样
            if (difference > 0) {
              for (let i = 0; i < difference; i++) {
                compareLabelsArr.push('')
                coordinate2.push('')
              }
            } else if (difference < 0) {
              for (let i = 0; i < Math.abs(difference); i++) {
                labelsArr.push('')
                coordinate1.push('')
              }
            }
            obj.value1 = coordinate1
            obj.value2 = coordinate2
            obj.time1 = '时间范围1'
            obj.time2 = '时间范围2'
            // 判断主X轴,调整顺序
            if (difference >= 0) {
              obj.xAxisIndexValue1 = 0
              obj.xAxisIndexValue2 = 1
              obj.label1 = labelsArr
              obj.label2 = compareLabelsArr
            } else {
              obj.xAxisIndexValue1 = 1
              obj.xAxisIndexValue2 = 0
              obj.label1 = compareLabelsArr
              obj.label2 = labelsArr
            }
            this.getSingleChart(obj)
          } else {
            this.isShowSingleChart = false
            // this.setDefaultLine()
          }
          break
        case '交易笔数':
          // 图表数据处理
          if ((this.orderCounts && this.orderCounts.length > 0) || (this.compareOrderCounts && this.compareOrderCounts.length > 0)) {
            this.isShowSingleChart = true
            const obj = {
              indicator: '交易笔数', // y轴名
              unit: '单', // y轴名单位
              time1: '', // 时间范围1
              time2: '', // 时间范围2
              label1: [], // 横坐标
              label2: [], // 横坐标
              value1: [], // 第一条线的值
              value2: [], // 第二条线的值
              xAxisIndexValue1: 0, // 设置主轴
              xAxisIndexValue2: 1 // 设置主轴
            }
            // 调整X轴长度和顺序
            const coordinate1 = this.orderCounts || [] // 坐标值
            const coordinate2 = this.compareOrderCounts || [] // 坐标值
            const labelsArr = JSON.parse(JSON.stringify(this.labels)) // X轴名
            const compareLabelsArr = JSON.parse(JSON.stringify(this.compareLabels)) // X轴名
            const difference = labelsArr.length - compareLabelsArr.length // 根据差值，补空，使两个X轴坐标数组长度一样
            if (difference > 0) {
              for (let i = 0; i < difference; i++) {
                compareLabelsArr.push('')
                coordinate2.push('')
              }
            } else if (difference < 0) {
              for (let i = 0; i < Math.abs(difference); i++) {
                labelsArr.push('')
                coordinate1.push('')
              }
            }
            obj.value1 = coordinate1
            obj.value2 = coordinate2
            obj.time1 = '时间范围1'
            obj.time2 = '时间范围2'
            // 判断主X轴,调整顺序
            if (difference >= 0) {
              obj.xAxisIndexValue1 = 0
              obj.xAxisIndexValue2 = 1
              obj.label1 = labelsArr
              obj.label2 = compareLabelsArr
            } else {
              obj.xAxisIndexValue1 = 1
              obj.xAxisIndexValue2 = 0
              obj.label1 = compareLabelsArr
              obj.label2 = labelsArr
            }
            this.getSingleChart(obj)
          } else {
            this.isShowSingleChart = false
            // this.setDefaultLine()
          }
          break
        case '客单价':
          // 图表数据处理
          if ((this.unitPrice && this.unitPrice.length > 0) || (this.compareUnitPrice && this.compareUnitPrice.length > 0)) {
            this.isShowSingleChart = true
            const obj = {
              indicator: '客单价', // y轴名
              unit: '元', // y轴名单位
              time1: '', // 时间范围1
              time2: '', // 时间范围2
              label1: [], // 横坐标
              label2: [], // 横坐标
              value1: [], // 第一条线的值
              value2: [], // 第二条线的值
              xAxisIndexValue1: 0, // 设置主轴
              xAxisIndexValue2: 1 // 设置主轴
            }
            // 调整X轴长度和顺序
            const coordinate1 = this.unitPrice || [] // 坐标值
            const coordinate2 = this.compareUnitPrice || [] // 坐标值
            const labelsArr = JSON.parse(JSON.stringify(this.labels)) // X轴名
            const compareLabelsArr = JSON.parse(JSON.stringify(this.compareLabels)) // X轴名
            const difference = labelsArr.length - compareLabelsArr.length // 根据差值，补空，使两个X轴坐标数组长度一样
            if (difference > 0) {
              for (let i = 0; i < difference; i++) {
                compareLabelsArr.push('')
                coordinate2.push('')
              }
            } else if (difference < 0) {
              for (let i = 0; i < Math.abs(difference); i++) {
                labelsArr.push('')
                coordinate1.push('')
              }
            }
            obj.value1 = coordinate1
            obj.value2 = coordinate2
            obj.time1 = '时间范围1'
            obj.time2 = '时间范围2'
            // 判断主X轴,调整顺序
            if (difference >= 0) {
              obj.xAxisIndexValue1 = 0
              obj.xAxisIndexValue2 = 1
              obj.label1 = labelsArr
              obj.label2 = compareLabelsArr
            } else {
              obj.xAxisIndexValue1 = 1
              obj.xAxisIndexValue2 = 0
              obj.label1 = compareLabelsArr
              obj.label2 = labelsArr
            }
            this.getSingleChart(obj)
          } else {
            this.isShowSingleChart = false
            // this.setDefaultLine()
          }
          break
        case '销售坪效':
          // 图表数据处理
          if ((this.salesEffects && this.salesEffects.length > 0) || (this.compareSalesEffects && this.compareSalesEffects.length > 0)) {
            this.isShowSingleChart = true
            const obj = {
              indicator: '销售坪效', // y轴名
              unit: '元/日/㎡', // y轴名单位
              time1: '', // 时间范围1
              time2: '', // 时间范围2
              label1: [], // 横坐标
              label2: [], // 横坐标
              value1: [], // 第一条线的值
              value2: [], // 第二条线的值
              xAxisIndexValue1: 0, // 设置主轴
              xAxisIndexValue2: 1 // 设置主轴
            }
            // 调整X轴长度和顺序
            const coordinate1 = this.salesEffects || [] // 坐标值
            const coordinate2 = this.compareSalesEffects || [] // 坐标值
            const labelsArr = JSON.parse(JSON.stringify(this.labels)) // X轴名
            const compareLabelsArr = JSON.parse(JSON.stringify(this.compareLabels)) // X轴名
            const difference = labelsArr.length - compareLabelsArr.length // 根据差值，补空，使两个X轴坐标数组长度一样
            if (difference > 0) {
              for (let i = 0; i < difference; i++) {
                compareLabelsArr.push('')
                coordinate2.push('')
              }
            } else if (difference < 0) {
              for (let i = 0; i < Math.abs(difference); i++) {
                labelsArr.push('')
                coordinate1.push('')
              }
            }
            obj.value1 = coordinate1
            obj.value2 = coordinate2
            obj.time1 = '时间范围1'
            obj.time2 = '时间范围2'
            // 判断主X轴,调整顺序
            if (difference >= 0) {
              obj.xAxisIndexValue1 = 0
              obj.xAxisIndexValue2 = 1
              obj.label1 = labelsArr
              obj.label2 = compareLabelsArr
            } else {
              obj.xAxisIndexValue1 = 1
              obj.xAxisIndexValue2 = 0
              obj.label1 = compareLabelsArr
              obj.label2 = labelsArr
            }
            this.getSingleChart(obj)
          } else {
            this.isShowSingleChart = false
            // this.setDefaultLine()
          }
          break
      }
    },
    showTotalChartData () {
      switch (this.indicator) {
        case '销售额':
          // 图表数据处理
          if (this.sumSalesAmounts && this.sumSalesAmounts.length > 0) {
            this.isShowTotalChart = true
            const obj = {
              indicator: '销售额', // y轴名
              unit: '万元', // y轴名单位
              label: this.sumLabels, // 横坐标
              value: this.sumSalesAmounts
            }
            this.getTotalChart(obj)
          } else {
            this.isShowTotalChart = false
            // this.setDefaultHistogram()
          }
          break
        case '交易笔数':
          // 图表数据处理
          if (this.sumOrderCounts && this.sumOrderCounts.length > 0) {
            this.isShowTotalChart = true
            const obj = {
              indicator: '交易笔数', // y轴名
              unit: '单', // y轴名单位
              label: this.sumLabels, // 横坐标
              value: this.sumOrderCounts
            }
            this.getTotalChart(obj)
          } else {
            this.isShowTotalChart = false
            // this.setDefaultHistogram()
          }
          break
        case '客单价':
          // 图表数据处理
          if (this.sumUnitPrice && this.sumUnitPrice.length > 0) {
            this.isShowTotalChart = true
            const obj = {
              indicator: '客单价', // y轴名
              unit: '元', // y轴名单位
              label: this.sumLabels, // 横坐标
              value: this.sumUnitPrice
            }
            this.getTotalChart(obj)
          } else {
            this.isShowTotalChart = false
            // this.setDefaultHistogram()
          }
          break
        case '销售坪效':
          // 图表数据处理
          if (this.sumSalesEffects && this.sumSalesEffects.length > 0) {
            this.isShowTotalChart = true
            const obj = {
              indicator: '销售坪效', // y轴名
              unit: '元/日/㎡', // y轴名单位
              label: this.sumLabels, // 横坐标
              value: this.sumSalesEffects
            }
            this.getTotalChart(obj)
          } else {
            this.isShowTotalChart = false
            // this.setDefaultHistogram()
          }
          break
      }
    },
    handleData (val) {
      this.timeGranularity = val
      switch (val) {
        case '60分钟颗粒度':
          this.getData()
          break
        case '30分钟颗粒度':
          this.getData()
          break
      }
    },
    // 选择时间
    setStartTimeA () {
      const start = new Date(this.startTimeA).getTime()
      const end = new Date(this.endTimeA).getTime()
      const difference = (end - start) / (1000 * 60 * 60 * 24)
      if (this.endTimeA) {
        if (start > end) {
          this.$message({
            type: 'warning',
            message: '开始时间不能大于结束时间'
          })
          return false
        }
        if (difference > 7) {
          this.$message({
            type: 'warning',
            message: '时间范围不能超过7天'
          })
          return false
        }
        this.getData()
      }
    },
    setEndTimeA () {
      const start = new Date(this.startTimeA).getTime()
      const end = new Date(this.endTimeA).getTime()
      const difference = (end - start) / (1000 * 60 * 60 * 24)
      if (this.startTimeA) {
        if (start > end) {
          this.$message({
            type: 'warning',
            message: '开始时间不能大于结束时间'
          })
          return false
        }
        if (difference > 7) {
          this.$message({
            type: 'warning',
            message: '时间范围不能超过7天'
          })
          return false
        }
        this.getData()
      }
    },
    setStartTimeB () {
      const start = new Date(this.startTimeB).getTime()
      const end = new Date(this.endTimeB).getTime()
      const difference = (end - start) / (1000 * 60 * 60 * 24)
      if (this.endTimeB) {
        if (start > end) {
          this.$message({
            type: 'warning',
            message: '开始时间不能大于结束时间'
          })
          return false
        }
        if (difference > 7) {
          this.$message({
            type: 'warning',
            message: '时间范围不能超过7天'
          })
          return false
        }
        this.getData()
      }
    },
    setEndTimeB () {
      const start = new Date(this.startTimeB).getTime()
      const end = new Date(this.endTimeB).getTime()
      const difference = (end - start) / (1000 * 60 * 60 * 24)
      if (this.startTimeB) {
        if (start > end) {
          this.$message({
            type: 'warning',
            message: '开始时间不能大于结束时间'
          })
          return false
        }
        if (difference > 7) {
          this.$message({
            type: 'warning',
            message: '时间范围不能超过7天'
          })
          return false
        }
        this.getData()
      }
    },
    // 切换图表
    changeChart () {
      if (this.detailedContrast) { // 明细对比图
        this.showSingleChartData()
      } else { // 总计对比
        this.showTotalChartData()
      }
    },
    // 获取图表
    getSingleChart (obj) {
      // console.log('obj', obj)
      const maxValue = this.isShowSingleChart ? null : 1750
      const mychart = document.getElementById('singleCharts')
      const divWidth = document.getElementById('contrast-chart')
      mychart.style.width = divWidth.offsetWidth + 'px'
      this.singleCharts = echarts.init(document.getElementById('singleCharts'))
      this.singleCharts.setOption({
        color: ['#3F8CFF', '#A0D7E7'],
        legend: {
          itemHeight: 10,
          itemWidth: 10,
          itemGap: 30,
          top: 2,
          right: 250,
          icon: 'circle',
          textStyle: {
            color: 'rgba(255, 255, 255, .45)',
            fontFamily: 'PingFang SC',
            fontSize: 12
          },
          data: [obj.time1, obj.time2]
        },
        grid: {
          top: '15%',
          left: '5%',
          right: '5%',
          bottom: '1%',
          containLabel: true
        },
        xAxis: [{
          data: obj.label1,
          boundaryGap: false,
          type: 'category',
          offset: 6,
          axisTick: {
            show: true
          },
          axisLabel: {
            color: '#808191'
          },
          axisLine: {
            lineStyle: {
              color: '#373A43'
            }
          },
          axisPointer: {
            lineStyle: {
              color: 'rgba(255,255,255,.1)'
            }
          }
        }, {
          show: false,
          data: obj.label2,
          boundaryGap: false,
          type: 'category',
          axisTick: {
            show: true
          },
          axisLabel: {
            color: 'rgba(255, 255, 255, .45)'
          },
          axisPointer: {
            lineStyle: {
              color: 'rgba(255,255,255,.1)'
            }
          }
        }],
        yAxis: {
          name: obj.indicator + '(' + obj.unit + ')',
          nameTextStyle: {
            color: '#808191',
            fontFamily: 'PingFang SC',
            fontSize: 12
          },
          min: 0,
          max: maxValue,
          type: 'value',
          axisTick: {
            show: false
          },
          splitLine: {
            lineStyle: {
              color: '#373A43',
              type: 'dashed'
            }
          },
          axisLine: {
            lineStyle: {
              color: '#373A43'
            }
          },
          axisLabel: {
            color: '#808191',
            formatter: '{value}'
          }
        },
        tooltip: {
          trigger: 'axis',
          backgroundColor: 'rgba(0,0,0,0.85)',
          textStyle: {
            fontSize: 12
          },
          formatter: (params) => {
            if (params.length > 1 || params[0].axisValue !== '') {
              let html = '<div style="padding:4px; font-family: PingFang SC;">'
              for (let i = 0; i < params.length; i++) {
                if (params[i].name) {
                  html += '<span style="color:#b2b3bd">' + params[i].marker + ' ' + params[i].seriesName + ' ' + params[i].name + '： ' + '</span>' + ((params[i].data || params[i].data === 0) ? params[i].data : '-') + obj.unit + '<br>'
                }
              }
              html += '</div>'
              return html
            }
          }
        },
        series: [
          {
            name: obj.time1,
            type: 'line',
            smooth: true,
            emphasis: {
              focus: 'series'
            },
            lineStyle: {
              color: '#3F8CFF'
            },
            xAxisIndex: obj.xAxisIndexValue1, // 设置X轴
            areaStyle: {
              opacity: 1,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: 'rgba(62, 140, 255, .3)'
              }, {
                offset: 1,
                color: 'rgba(62, 140, 255, 0)'
              }])
            },
            data: obj.value1
          },
          {
            name: obj.time2,
            type: 'line',
            smooth: true,
            emphasis: {
              focus: 'series'
            },
            lineStyle: {
              color: 'rgba(160, 215, 231, 1)'
            },
            xAxisIndex: obj.xAxisIndexValue2, // 设置X轴
            areaStyle: {
              opacity: 1,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: 'rgba(160, 215, 231, .3)'
              }, {
                offset: 1,
                color: 'rgba(160, 215, 231, 0)'
              }])
            },
            data: obj.value2
          }
        ]
      })
      this.$nextTick(() => {
        window.addEventListener('resize', () => {
          mychart.style.width = divWidth.offsetWidth + 'px'
          this.singleCharts.resize()
        })
      })
    },
    // 画图表-总计对比
    getTotalChart (obj) {
      // console.log('getTotalChart.obj', obj)
      const maxValue = this.isShowTotalChart ? null : 1750
      const mychart = document.getElementById('totalCharts')
      const divWidth = document.getElementById('contrast-chart')
      mychart.style.width = divWidth.offsetWidth + 'px'
      this.totalCharts = echarts.init(document.getElementById('totalCharts'))
      this.totalCharts.setOption({
        color: ['#3F8CFF'],
        tooltip: {
          trigger: 'axis',
          backgroundColor: 'rgba(0,0,0,0.85)',
          textStyle: {
            fontSize: 12
          },
          formatter: function (params) {
            return '<span style="color: #808191;">' + params[0].axisValueLabel + ': </span>' + ((params[0].data || params[0].data === 0) ? params[0].data : '-') + obj.unit
          },
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: {
          top: '15%',
          left: '5%',
          right: '5%',
          bottom: '1%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          offset: 6,
          axisTick: {
            show: true
          },
          axisLabel: {
            color: '#808191'
          },
          axisLine: {
            lineStyle: {
              color: '#373A43'
            }
          },
          data: obj.label
        },
        yAxis: {
          name: obj.indicator + '(' + obj.unit + ')',
          type: 'value',
          nameTextStyle: {
            color: '#808191',
            fontFamily: 'PingFang SC',
            fontSize: 12
          },
          splitLine: {
            lineStyle: {
              color: '#373A43',
              type: 'dashed'
            }
          },
          min: 0,
          max: maxValue,
          axisLine: {
            lineStyle: {
              color: '#373A43'
            }
          },
          axisLabel: {
            color: '#808191',
            formatter: '{value}'
          }
        },
        series: [{
          barWidth: 60,
          data: obj.value,
          type: 'bar',
          label: { // 图形上的文本标签
            show: true,
            position: 'top', // 相对位置
            color: 'rgba(255, 255, 255, .45)'
          }
        }]
      })
      this.$nextTick(() => {
        window.addEventListener('resize', () => {
          mychart.style.width = divWidth.offsetWidth + 'px'
          this.totalCharts.resize()
        })
      })
    },
    // 加载更多
    loadMore (e) {
      if (e.srcElement.scrollTop + e.srcElement.clientHeight >= e.srcElement.scrollHeight) {
        this.getStoreSalesGoodsPageList()
      }
    },
    // 获取货品信息
    getStoreSalesGoodsPageList () {
      const params1 = { // 时间范围1
        projectId: this.filtroProject,
        storeCode: this.filtroStore,
        salesStartDate: this.startTimeA,
        salesEndDate: this.endTimeA,
        currentPage: this.currentPage,
        pageSize: 10
      }
      const params2 = { // 时间范围2
        projectId: this.filtroProject,
        storeCode: this.filtroStore,
        salesStartDate: this.startTimeB,
        salesEndDate: this.endTimeB,
        currentPage: this.currentPage,
        pageSize: 10
      }
      this.axios.get(api.getStoreSalesGoodsPageList, { params: params1 }).then(res => {
        if (res.data.code === 0) {
          this.showTableData = true
          const resData = res.data.data
          if (this.tableDataA.length < resData.total) {
            const Arr = resData.dataList.map(item => {
              return {
                goodsName: item.goodsName,
                goodsNumber: item.goodsNumber,
                goodsAmount: item.goodsAmount,
                makeUp: false // 判断是否是补差值的数据
              }
            })
            this.tableDataA.push(...Arr)
          }
          this.axios.get(api.getStoreSalesGoodsPageList, { params: params2 }).then(resource => {
            if (resource.data.code === 0) {
              const resourceData = resource.data.data
              if (this.tableDataB.length < resourceData.total) {
                const Arr = resourceData.dataList.map(item => {
                  return {
                    goodsName: item.goodsName,
                    goodsNumber: item.goodsNumber,
                    goodsAmount: item.goodsAmount,
                    makeUp: false // 判断是否是补差值的数据
                  }
                })
                this.tableDataB.push(...Arr)
              }
              if (this.tableDataA.length > 0 || this.tableDataB.length > 0) {
                // 补空值，为了让页面表格好看一些
                const differenceValue = this.tableDataA.length - this.tableDataB.length
                const value = Math.abs(differenceValue)
                if (differenceValue > 0) {
                  for (let i = 0; i < value; i++) {
                    const temp = {
                      goodsName: '-',
                      goodsNumber: '-',
                      goodsAmount: '-',
                      makeUp: true // 判断是否是补差值的数据
                    }
                    this.tableDataB.push(temp)
                  }
                } else if (differenceValue < 0) {
                  for (let i = 0; i < value; i++) {
                    const temp = {
                      goodsName: '-',
                      goodsNumber: '-',
                      goodsAmount: '-',
                      makeUp: true // 判断是否是补差值的数据
                    }
                    this.tableDataA.push(temp)
                  }
                }
              } else {
                this.setDefaultDate()
              }
              // 页码增加
              this.currentPage++
            }
          })
        } else {
          this.setDefaultDate()
        }
      })
    }
    // exportOutFunc () {
    //   console.log('导出数据')
    // }
  }
}
</script>

<style lang="stylus" scoped>
.view1
  padding-top 60px
  background #272930
  opacity 1
  border-radius 0 0 2px 2px
  .selectBtn-view
    margin-top 17px
    padding-left 24px
    line-height 33px
    display flex
    flex-flow wrap
    .type-item
      margin-top 15px
      display flex
      font-size 14px
      font-family PingFang SC
      color #B2B3BD
      .type-item-btn
        padding 0 3px
        height 33px
        border 1px solid #373A43
        opacity 1
        border-radius 2px
        span
          display inline-block
          width 77px
          height 27px
          line-height 27px
          font-size 14px
          font-family PingFang SC
          font-weight 500
          color rgba(255, 255, 255, 0.5)
          text-align center
          margin-right -1px
          cursor pointer
          &.active
            background #FFA134
            color #fff
            border-color #FFA134
        span:first-child
          border-radius 2px 0px 0px 2px
        span:last-child
          border-radius 0px 2px 2px 0px
      .type-item-select
        background-color #373A43
        span
          color #B2B3BD
        & >>> .el-select
          width 154px
        & >>> .el-date-editor
          width 180px
          input
            text-align center
    .VS
      width 42px
      text-align center
      font-weight 800
      color #808191
  .contrast-checkBox
    width 100%
    position absolute
  .checkBox
    margin-right 24px
    & label >>> .el-checkbox__inner
      border 1px #808191 solid !important
    & label >>> .el-checkbox__input.is-checked .el-checkbox__inner
      background-color #ffa134 !important
      border-color #ffa134 !important
    & label >>> .is-checked + span
      color #fff !important
    & label >>> .el-checkbox__input.is-checked
      border-color #ffa134 !important
  .contrast
    display flex
    margin-top 63px
    height 590px
    .contrast-data
      width 40%
      ul
        font-size 12px
        font-family PingFang SC
        font-weight 400
        color #808191
        li
          margin-right 24px
          span
            display inline-block
            width 8px
            height 8px
            border-radius 50%
            background #3F8CFF
          &:nth-child(2) span
            background #A0D7E7
      .contrast-content
        margin 40px 24px auto 24px
        .contrast-content-title
          justify-content space-between
          display flex
          margin-bottom 15px
          span
            font-size 16px
            font-family PingFang SC
            color #FFFFFF
          span:nth-child(2)
            font-size 14px
            font-weight 400
            color #B2B3BD
    .contrast-chart
      position relative
      border-right 1px solid #373A43
      width 60%
      .charts-box
        width 100%
        height 537px
.view2
  padding-top 40px
  padding-bottom 40px
  border-top 1px solid #373A43
  background #272930
  .exportOut
    margin-right 25px
    font-size 16px
    font-weight 400
    color #B2B3BD
    cursor pointer
    &:before
      margin-right 6px
      font-family 'iconfont'
      content '\e60b'
  .w-table
    display flex
    justify-content center
    height 345px
    margin-top 24px
    overflow-y auto
    font-size 14px
    font-family PingFang SC
    table
      width 100%
      thead
        tr
          height 49px
          color #B2B3BD
          &:first-child
            font-weight bold
            color #FFF
          th
            border 1px solid #373A43
            text-align center
            span
              color #808191
      tbody
        tr
          height 49px
          color #808191
          font-size 14px
          &:hover td
            background-color #23252b
          td
            border 1px solid #373A43
            text-align center
            &:nth-child(3)
              color #fff
            &:nth-child(4)
              color #fff
            &:nth-child(7)
              color #fff
            &:nth-child(8)
              color #fff
      .greyTD td
        color #808191 !important
    table:first-child
      margin-left 24px
    table:nth-child(2)
      margin-right 24px
    table:nth-child(2) td
      border-left none
    table:nth-child(2) th
      border-left none
.contrast-title
  padding-left 24px
  font-size 16px
  font-family PingFang SC
  font-weight bold
  color #FFFFFF
.clear
  clear both
.mr-70
  margin-right 70px
// elm-ui样式
// 1.下拉框
/deep/ .el-input__inner
  height 33px
  background-color #373A43
  color #808191
  border none
  border-radius 2px
  padding 0 18px
// 2.日期组件样式
// （1）日期icon
/deep/ .el-input__prefix
  display none
/deep/ .el-date-editor .el-input__suffix
  display none
// （2）输入框禁止点击时
/deep/ .is-disabled .el-input__inner
  color #808191 !important
  background-color #373a43 !important
</style>
