<template>
  <div>
    <div class="view1">
      <div class="selectBtn-view">
        <div class="type-item mr-70">
          左轴指标：
          <div class="type-item-btn">
            <span
              v-for="(item,index) in tabsListLeft"
              :key="index"
              @click="setIndicator(0, index)"
              :class="[tabsLeftValue === index ? 'active': '']"
            >{{item}}</span>
          </div>
        </div>
        <div class="type-item mr-70">
          右轴指标：
          <div class="type-item-btn">
            <span
              v-for="(item,index) in tabsListRight"
              :key="index"
              @click="setIndicator(1, index)"
              :class="[tabsRightValue === index ? 'active': '']"
            >{{item}}</span>
          </div>
        </div>
        <div class="type-item">
          时间范围：
          <div class="type-item-select">
            <el-select
              popper-class="w-block-select-down yellow-select-panel"
              v-model="timeType"
              size="small"
              placeholder="请选择"
              @change="handleData"
            >
              <el-option
                v-for="item in timeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <span class="segmentation">|</span>
            <el-date-picker
              popper-class="orange-el-date-picker"
              :disabled="noEdit"
              v-model="startTime"
              type="date"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptions_start"
              @change="setStartTime()"
            >
            </el-date-picker>
            <span :class="noEdit?'isDisabled':''">~</span>
            <el-date-picker
              popper-class="orange-el-date-picker"
              :disabled="noEdit"
              v-model="endTime"
              type="date"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptions_end"
              @change="setEndTime()"
            >
            </el-date-picker>
          </div>
        </div>
      </div>
      <div class="analysis">
        <div class="analysis-data">
          <h3 class="w-title-gradients">销售关联分析</h3>
          <ul
            class="info-ul"
            :class="tabsLeftValue===2?'pl-0':''"
          >
            <li
              v-for="(item, index) in salesRelativeDataList"
              :key="index"
              v-show="!(index===2 && tabsLeftValue===2) && !(index===4 && tabsLeftValue===2) "
            >
              <p v-if="index!==3 && item[tabsLeftValue]">{{item[tabsLeftValue]}}</p>
              <p v-if="index===3">{{item[tabsRightValue]}}</p>
              <span class="num-span">{{salesRelativeData[index] || '-'}}</span>
            </li>
          </ul>
        </div>
        <div class="analysis-chart">
          <div class="checkBox">
            <el-checkbox
              @change="showDatum"
              v-model="information.isShowDatumLine1"
            >{{information.datumLine1}}</el-checkbox>
            <el-checkbox
              v-if="tabsLeftValue === 0 || tabsLeftValue === 3"
              @change="showDatum"
              v-model="information.isShowDatumLine2"
            >{{information.datumLine2}}</el-checkbox>
          </div>
          <div
            id="charts"
            class="charts-box"
          ></div>
        </div>
      </div>
    </div>
    <div class="view2">
      <h3 class="w-title-gradients">明细列表</h3>
      <span
        class="exportOut fr"
        @click="exportOutFunc()"
      >导出数据</span>
      <div class="clear"></div>
      <div
        class="w-table"
        :class="showTableData?'w-singed-table':'grey-singed-table'"
      >
        <table>
          <thead>
            <tr>
              <th width="5.96%">序号</th>
              <th width="12.28%">时间</th>
              <th width="13.83%">销售额（元）</th>
              <th width="14.21%">交易笔数(单)</th>
              <th width="14.95%">客单价(元)</th>
              <th width="15.63%">销售坪效(元/日/m²)</th>
              <th width="13.40%">客流量(人次）</th>
              <th>车流量(车次)</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in tableData"
              :key="index"
            >
              <td>{{index + 1}}</td>
              <td>{{item.date}}</td>
              <td>{{item.salesAmount}}</td>
              <td>{{item.orderCount}}</td>
              <td>{{item.unitPrice}}</td>
              <td>{{item.salesEffect}}</td>
              <td>{{item.trafficFlowNum}}</td>
              <td>{{item.passengerFlowNum}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import echarts from 'echarts'
import { dateFormat } from '@/utils/index'
export default {
  name: 'salesLink',
  data () {
    return {
      tabsLeftValue: 0,
      tabsRightValue: 0,
      tabsListLeft: ['销售额', '交易笔数', '客单价', '销售坪效'],
      tabsListRight: ['客流', '车流'],
      salesRelativeDataList: [
        [
          '销售总额(万元)',
          '累计交易笔数(单)',
          '客单价(元)',
          '日均销售坪效(元/日/㎡)'
        ],
        [
          '日均销售额(万元/日)',
          '日均交易笔数(单/日)',
          '同业态店铺平均客单价(元)',
          '项目日均销售坪效(元/日/㎡)'
        ],
        [
          '同楼层店铺日均销售额(万元/日)',
          '同楼层店铺日均交易笔数(单/日)',
          '',
          '同楼层店铺日均销售坪效(元/日/㎡)'
        ],
        [
          '日均客流量(千人次/日)',
          '日均车流量(千车次/日)'
        ],
        [
          '同业态店铺日均销售额(万元/日)',
          '同业态店铺日均交易笔数(单/日)',
          '',
          '同业态店铺日均销售坪效(元/日/㎡)'
        ]
      ],
      salesRelativeData: [],
      tableData: [],
      showTableData: true,
      chartsData: [],
      information: { // 关联分析中展示的数据
        data1: '',
        data2: '',
        data3: '',
        data4: '',
        data5: '',
        datumLine1: '同楼层店铺日均销售额', // 基准线名称
        datumLine2: '同业态店铺日均销售额', // 基准线名称
        isShowDatumLine1: false, // 是否显示基准线
        isShowDatumLine2: false // 是否显示基准线
      },
      infoList: {},
      timeType: '近7天',
      timeOptions: [{
        value: '近7天',
        label: '近7天'
      }, {
        value: '近30天',
        label: '近30天'
      }, {
        value: '近90天',
        label: '近90天'
      }, {
        value: '近一年',
        label: '近一年'
      }, {
        value: '自定义',
        label: '自定义'
      }],
      startTime: '',
      endTime: '',
      pickerOptions_start: {
        disabledDate: (time) => {
          if (this.endTime) {
            return time.getTime() > Date.now() || time.getTime() > this.endTime
          } else {
            return time.getTime() > Date.now()
          }
        }
      },
      pickerOptions_end: {
        disabledDate: (time) => {
          return time.getTime() < this.startTime || time.getTime() > Date.now()
        }
      },
      charts: {},
      noEdit: false,
      isShowChart: false
    }
  },
  props: {
    filtroProject: {
      type: Number
    },
    filtroStore: {
      type: String
    }
  },
  watch: {
    filtroStore (newValue, oldValue) {
      this.getData()
    }
  },
  mounted () {
    this.setDefaultDate()
    this.setCurrentTime()
    this.getData()
  },
  methods: {
    // 生成时间范围数组
    getEveryDayDateByBetweenDate (start, end) {
      const startTime = new Date(start).getTime()
      const endTime = new Date(end).getTime()
      const dateList = []
      for (let i = startTime; i <= endTime; i += 3600 * 1000 * 24 * 1) {
        const time = dateFormat('yyyy-MM-dd', new Date(i))
        dateList.push(time)
      }
      return dateList
    },
    setDefaultChart () {
      this.chartsData = []
      this.infoList = {
        avgOrderCount: '', // 日均销售笔数
        avgPassengerFlowNum: '', // 日均客流量
        avgSalesAmount: '', // 日均销售额
        avgSalesEffect: '', // 日均销售坪效
        avgTrafficFlowNum: '', // 日均车流量
        avgUnitPrice: '', // 客单价
        lcAvgOrderCount: '', // 同楼层日均交易笔数
        lcAvgSalesAmount: '', // 同楼层日均销售额
        lcAvgSalesEffect: '', // 同楼层日均销售坪效
        lcAvgUnitPrice: '', // 同楼层日均客单价
        mallAvgSalesEffect: '', // 项目日均销售坪效
        totalOrderCount: '', // 累计销售笔数
        totalSalesAmount: '', // 累计销售额
        ytAvgOrderCount: '', // 同业态日均交易笔数
        ytAvgSalesAmount: '', // 同业态日均销售额
        ytAvgSalesEffect: '', // 同业态日均销售坪效
        ytAvgUnitPrice: '' // 同业态日均客单价
      }
      const Arr = this.getEveryDayDateByBetweenDate(this.startTime, this.endTime)
      for (let i = 0; i < Arr.length; i++) {
        const obj = {
          measureDate: Arr[i],
          orderCount: '',
          passengerFlowNum: '',
          salesAmount: '',
          salesEffect: '',
          trafficFlowNum: '',
          unitPrice: ''
        }
        this.chartsData.push(obj)
      }
    },
    // 设置时间初始值
    setCurrentTime () {
      // 设置默认时间
      const end = new Date()
      const start = new Date()
      end.setTime(end.getTime() - 3600 * 1000 * 24 * 1)
      this.endTime = dateFormat('yyyy-MM-dd', end)
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
      this.startTime = dateFormat('yyyy-MM-dd', start)
      this.noEdit = true
    },
    // 省缺时显示的数据
    setDefaultDate () {
      this.tableData = []
      this.showTableData = false
      for (let i = 0; i < 8; i++) {
        const obj = {
          date: '-',
          salesAmount: '-',
          orderCount: '-',
          unitPrice: '-',
          salesEffect: '-',
          trafficFlowNum: '-',
          passengerFlowNum: '-'
        }
        this.tableData.push(obj)
      }
    },
    // 获取数据
    getData () {
      // 获取数据并处理
      const params = {
        projectId: this.filtroProject,
        storeCode: this.filtroStore,
        salesStartDate: this.startTime,
        salesEndDate: this.endTime
      }
      this.axios.get(api.getStoreSalesRelateAnalysis, { params: params }).then(res => {
        if (res.data.code === 0) {
          this.isShowChart = true
          const resData = res.data.data
          // 一、图表
          this.chartsData = resData.measureStoreDayList
          // 二、销售关联分析
          const relateDate = resData.storeRelateDataVo
          this.infoList = {
            avgOrderCount: '', // 日均销售笔数
            avgPassengerFlowNum: '', // 日均客流量
            avgSalesAmount: '', // 日均销售额
            avgSalesEffect: '', // 日均销售坪效
            avgTrafficFlowNum: '', // 日均车流量
            avgUnitPrice: '', // 客单价
            lcAvgOrderCount: '', // 同楼层日均交易笔数
            lcAvgSalesAmount: '', // 同楼层日均销售额
            lcAvgSalesEffect: '', // 同楼层日均销售坪效
            lcAvgUnitPrice: '', // 同楼层日均客单价
            mallAvgSalesEffect: '', // 项目日均销售坪效
            totalOrderCount: '', // 累计销售笔数
            totalSalesAmount: '', // 累计销售额
            ytAvgOrderCount: '', // 同业态日均交易笔数
            ytAvgSalesAmount: '', // 同业态日均销售额
            ytAvgSalesEffect: '', // 同业态日均销售坪效
            ytAvgUnitPrice: '' // 同业态日均客单价
          }
          if (relateDate) {
            // 销售额
            // 累计销售额
            if (relateDate.totalSalesAmount !== '' && relateDate.totalSalesAmount !== undefined && relateDate.totalSalesAmount !== null) {
              this.infoList.totalSalesAmount = (relateDate.totalSalesAmount / 10000).toFixed(2) // 单位转换：元转万元
            } else {
              this.infoList.totalSalesAmount = ''
            }
            // 日均销售额
            if (relateDate.avgSalesAmount !== '' && relateDate.avgSalesAmount !== undefined && relateDate.avgSalesAmount !== null) {
              this.infoList.avgSalesAmount = (relateDate.avgSalesAmount / 10000).toFixed(2) // 单位转换：元转万元
            } else {
              this.infoList.avgSalesAmount = ''
            }
            // 同楼层日均销售额
            if (relateDate.lcAvgSalesAmount !== '' && relateDate.lcAvgSalesAmount !== undefined && relateDate.lcAvgSalesAmount !== null) {
              this.infoList.lcAvgSalesAmount = (relateDate.lcAvgSalesAmount / 10000).toFixed(2) // 单位转换：元转万元
            } else {
              this.infoList.lcAvgSalesAmount = ''
            }
            // 同业态日均销售额
            if (relateDate.ytAvgSalesAmount !== '' && relateDate.ytAvgSalesAmount !== undefined && relateDate.ytAvgSalesAmount !== null) {
              this.infoList.ytAvgSalesAmount = (relateDate.ytAvgSalesAmount / 10000).toFixed(2) // 单位转换：元转万元
            } else {
              this.infoList.ytAvgSalesAmount = ''
            }
            // 交易笔数
            // 累计销售笔数
            if (relateDate.totalOrderCount !== '' && relateDate.totalOrderCount !== undefined && relateDate.totalOrderCount !== null) {
              this.infoList.totalOrderCount = relateDate.totalOrderCount.toFixed(2)
            } else {
              this.infoList.totalOrderCount = ''
            }
            // 日均销售笔数
            if (relateDate.avgOrderCount !== '' && relateDate.avgOrderCount !== undefined && relateDate.avgOrderCount !== null) {
              this.infoList.avgOrderCount = relateDate.avgOrderCount.toFixed(2)
            } else {
              this.infoList.avgOrderCount = ''
            }
            // 同楼层日均交易笔数
            if (relateDate.lcAvgOrderCount !== '' && relateDate.lcAvgOrderCount !== undefined && relateDate.lcAvgOrderCount !== null) {
              this.infoList.lcAvgOrderCount = relateDate.lcAvgOrderCount.toFixed(2)
            } else {
              this.infoList.lcAvgOrderCount = ''
            }
            // 同业态日均交易笔数
            if (relateDate.ytAvgOrderCount !== '' && relateDate.ytAvgOrderCount !== undefined && relateDate.ytAvgOrderCount !== null) {
              this.infoList.ytAvgOrderCount = relateDate.ytAvgOrderCount.toFixed(2)
            } else {
              this.infoList.ytAvgOrderCount = ''
            }
            // 客单价
            // 客单价
            if (relateDate.avgUnitPrice !== '' && relateDate.avgUnitPrice !== undefined && relateDate.avgUnitPrice !== null) {
              this.infoList.avgUnitPrice = relateDate.avgUnitPrice.toFixed(2)
            } else {
              this.infoList.avgUnitPrice = ''
            }
            // 同业态日均客单价
            if (relateDate.ytAvgUnitPrice !== '' && relateDate.ytAvgUnitPrice !== undefined && relateDate.ytAvgUnitPrice !== null) {
              this.infoList.ytAvgUnitPrice = relateDate.ytAvgUnitPrice.toFixed(2)
            } else {
              this.infoList.ytAvgUnitPrice = ''
            }
            // 同楼层日均客单价
            if (relateDate.lcAvgUnitPrice !== '' && relateDate.lcAvgUnitPrice !== undefined && relateDate.lcAvgUnitPrice !== null) {
              this.infoList.lcAvgUnitPrice = relateDate.lcAvgUnitPrice.toFixed(2)
            } else {
              this.infoList.lcAvgUnitPrice = ''
            }
            // 销售坪效
            // 日均销售坪效
            if (relateDate.avgSalesEffect !== '' && relateDate.avgSalesEffect !== undefined && relateDate.avgSalesEffect !== null) {
              this.infoList.avgSalesEffect = relateDate.avgSalesEffect.toFixed(2)
            } else {
              this.infoList.avgSalesEffect = ''
            }
            // 项目日均销售坪效
            if (relateDate.mallAvgSalesEffect !== '' && relateDate.mallAvgSalesEffect !== undefined && relateDate.mallAvgSalesEffect !== null) {
              this.infoList.mallAvgSalesEffect = relateDate.mallAvgSalesEffect.toFixed(2)
            } else {
              this.infoList.mallAvgSalesEffect = ''
            }
            // 同楼层日均销售坪效
            if (relateDate.lcAvgSalesEffect !== '' && relateDate.lcAvgSalesEffect !== undefined && relateDate.lcAvgSalesEffect !== null) {
              this.infoList.lcAvgSalesEffect = relateDate.lcAvgSalesEffect.toFixed(2)
            } else {
              this.infoList.lcAvgSalesEffect = ''
            }
            // 同业态日均销售坪效
            if (relateDate.ytAvgSalesEffect !== '' && relateDate.ytAvgSalesEffect !== undefined && relateDate.ytAvgSalesEffect !== null) {
              this.infoList.ytAvgSalesEffect = relateDate.ytAvgSalesEffect.toFixed(2)
            } else {
              this.infoList.ytAvgSalesEffect = ''
            }
            // 日均客流量
            if (relateDate.avgPassengerFlowNum !== '' && relateDate.avgPassengerFlowNum !== undefined && relateDate.avgPassengerFlowNum !== null) {
              this.infoList.avgPassengerFlowNum = relateDate.avgPassengerFlowNum.toFixed(2)
            } else {
              this.infoList.avgPassengerFlowNum = ''
            }
            // 日均客流量
            if (relateDate.avgTrafficFlowNum !== '' && relateDate.avgTrafficFlowNum !== undefined && relateDate.avgTrafficFlowNum !== null) {
              this.infoList.avgTrafficFlowNum = relateDate.avgTrafficFlowNum.toFixed(2)
            } else {
              this.infoList.avgTrafficFlowNum = ''
            }
          }
          // 三、明细列表
          this.tableData = [] // 重置数据
          this.showTableData = true
          this.tableData = resData.measureStoreDayList.map(item => {
            const obj = {
              date: item.measureDate ? item.measureDate : '-',
              salesAmount: item.salesAmount ? item.salesAmount : '-',
              orderCount: (item.orderCount === 0 || item.orderCount !== null) ? item.orderCount : '-',
              unitPrice: item.unitPrice ? item.unitPrice : '-',
              salesEffect: item.salesEffect ? item.salesEffect : '-',
              trafficFlowNum: (item.trafficFlowNum === 0 || item.trafficFlowNum !== null) ? item.trafficFlowNum : '0',
              passengerFlowNum: (item.passengerFlowNum === 0 || item.passengerFlowNum !== null) ? item.passengerFlowNum : '0'
            }
            return obj
          })
          // 展示数据
          // this.showData()
        } else {
          this.isShowChart = false
          // 明细列表-省缺
          this.setDefaultDate()
          this.setDefaultChart()
        }
        this.showData()
      })
    },
    showData () {
      switch (this.tabsLeftValue) {
        case 0: // 销售额
          // 关联数据展示
          this.salesRelativeData = []
          this.salesRelativeData.push(
            this.infoList.totalSalesAmount,
            this.infoList.avgSalesAmount,
            this.infoList.lcAvgSalesAmount,
            '',
            this.infoList.ytAvgSalesAmount
          )
          this.information.datumLine1 = '同楼层店铺日均销售额'
          this.information.datumLine2 = '同业态店铺日均销售额'
          // 图表数据处理
          if (this.chartsData.length > 0) {
            // 客流
            if (this.tabsRightValue === 0) {
              const obj = {
                indicator: ['销售额', '客流'], // 左右轴
                unit: ['万元', '千人次/日'], // 左右轴单位
                label: [], // 横坐标
                value1: [], // 第一条线的值
                value2: [], // 第二条线的值
                markLine1: this.infoList.lcAvgSalesAmount ? this.infoList.lcAvgSalesAmount : 0,
                markLine2: this.infoList.ytAvgSalesAmount ? this.infoList.ytAvgSalesAmount : 0
              }
              for (let i = 0; i < this.chartsData.length; i++) {
                obj.label.push(this.chartsData[i].measureDate)
                obj.value1.push(this.chartsData[i].salesAmount ? (this.chartsData[i].salesAmount / 10000).toFixed(2) : '') // 销售额单位换算，元转万元
                obj.value2.push(this.chartsData[i].passengerFlowNum)
              }
              this.getChart(obj)
            } else if (this.tabsRightValue === 1) {
              const obj = {
                indicator: ['销售额', '车流'], // 左右轴
                unit: ['万元', '千车次/日'], // 左右轴单位
                label: [], // 横坐标
                value1: [], // 第一条线的值
                value2: [], // 第二条线的值
                markLine1: this.infoList.lcAvgSalesAmount ? this.infoList.lcAvgSalesAmount : 0,
                markLine2: this.infoList.ytAvgSalesAmount ? this.infoList.ytAvgSalesAmount : 0
              }
              for (let i = 0; i < this.chartsData.length; i++) {
                obj.label.push(this.chartsData[i].measureDate)
                obj.value1.push(this.chartsData[i].salesAmount ? (this.chartsData[i].salesAmount / 10000).toFixed(2) : '') // 销售额单位换算，元转万元
                obj.value2.push(this.chartsData[i].trafficFlowNum)
              }
              this.getChart(obj)
            }
          }
          break
        case 1: // 交易笔数
          // 关联数据展示
          this.salesRelativeData = []
          this.salesRelativeData.push(
            this.infoList.totalOrderCount,
            this.infoList.avgOrderCount,
            this.infoList.lcAvgOrderCount,
            '',
            this.infoList.ytAvgOrderCount
          )
          this.information.datumLine1 = '同业态店铺日均交易笔数'
          // 图表数据处理
          if (this.chartsData.length > 0) {
            // 客流
            if (this.tabsRightValue === 0) {
              const obj = {
                indicator: ['交易笔数', '客流'], // 左右轴
                unit: ['单', '千人次/日'], // 左右轴单位
                label: [], // 横坐标
                value1: [], // 第一条线的值
                value2: [], // 第二条线的值
                markLine1: this.infoList.ytAvgOrderCount ? this.infoList.ytAvgOrderCount : 0
              }
              for (let i = 0; i < this.chartsData.length; i++) {
                obj.label.push(this.chartsData[i].measureDate)
                obj.value1.push(this.chartsData[i].orderCount)
                obj.value2.push(this.chartsData[i].passengerFlowNum)
              }
              this.getChart(obj)
            } else if (this.tabsRightValue === 1) {
              const obj = {
                indicator: ['交易笔数', '车流'], // 左右轴
                unit: ['单', '千车次/日'], // 左右轴单位
                label: [], // 横坐标
                value1: [], // 第一条线的值
                value2: [], // 第二条线的值
                markLine1: this.infoList.ytAvgOrderCount ? this.infoList.ytAvgOrderCount : 0
              }
              for (let i = 0; i < this.chartsData.length; i++) {
                obj.label.push(this.chartsData[i].measureDate)
                obj.value1.push(this.chartsData[i].orderCount)
                obj.value2.push(this.chartsData[i].trafficFlowNum)
              }
              this.getChart(obj)
            }
          }
          break
        case 2: // 客单价
          // 关联数据展示
          this.salesRelativeData = []
          this.salesRelativeData.push(
            this.infoList.avgUnitPrice,
            this.infoList.ytAvgUnitPrice
          )
          this.information.datumLine1 = '同业态店铺平均客单价'
          // 图表数据处理
          if (this.chartsData.length > 0) {
            // 客流
            if (this.tabsRightValue === 0) {
              const obj = {
                indicator: ['客单价', '客流'], // 左右轴
                unit: ['元', '千人次/日'], // 左右轴单位
                label: [], // 横坐标
                value1: [], // 第一条线的值
                value2: [], // 第二条线的值
                markLine1: this.infoList.ytAvgUnitPrice ? this.infoList.ytAvgUnitPrice : 0
              }
              for (let i = 0; i < this.chartsData.length; i++) {
                obj.label.push(this.chartsData[i].measureDate)
                obj.value1.push(this.chartsData[i].unitPrice)
                obj.value2.push(this.chartsData[i].passengerFlowNum)
              }
              this.getChart(obj)
            } else if (this.tabsRightValue === 1) {
              const obj = {
                indicator: ['客单价', '车流'], // 左右轴
                unit: ['元', '千车次/日'], // 左右轴单位
                label: [], // 横坐标
                value1: [], // 第一条线的值
                value2: [], // 第二条线的值
                markLine1: this.infoList.ytAvgUnitPrice ? this.infoList.ytAvgUnitPrice : 0
              }
              for (let i = 0; i < this.chartsData.length; i++) {
                obj.label.push(this.chartsData[i].measureDate)
                obj.value1.push(this.chartsData[i].unitPrice)
                obj.value2.push(this.chartsData[i].trafficFlowNum)
              }
              this.getChart(obj)
            }
          }
          break
        case 3: // 销售坪效
          // 关联数据展示
          this.salesRelativeData = []
          this.salesRelativeData.push(
            this.infoList.avgSalesEffect,
            this.infoList.mallAvgSalesEffect,
            this.infoList.lcAvgSalesEffect,
            '',
            this.infoList.ytAvgSalesEffect
          )
          this.information.datumLine1 = '同楼层店铺日均销售坪效'
          this.information.datumLine2 = '同业态店铺日均销售坪效'
          // 图表数据处理
          if (this.chartsData.length > 0) {
            // 客流
            if (this.tabsRightValue === 0) {
              const obj = {
                indicator: ['销售坪效', '客流'], // 左右轴
                unit: ['元/日/㎡', '千人次/日'], // 左右轴单位
                label: [], // 横坐标
                value1: [], // 第一条线的值
                value2: [], // 第二条线的值
                markLine1: this.infoList.lcAvgSalesEffect ? this.infoList.lcAvgSalesEffect : 0,
                markLine2: this.infoList.ytAvgSalesEffect ? this.infoList.ytAvgSalesEffect : 0
              }
              for (let i = 0; i < this.chartsData.length; i++) {
                obj.label.push(this.chartsData[i].measureDate)
                obj.value1.push(this.chartsData[i].salesEffect)
                obj.value2.push(this.chartsData[i].passengerFlowNum)
              }
              this.getChart(obj)
            } else if (this.tabsRightValue === 1) {
              const obj = {
                indicator: ['销售坪效', '车流'], // 左右轴
                unit: ['元/日/㎡', '千车次/日'], // 左右轴单位
                label: [], // 横坐标
                value1: [], // 第一条线的值
                value2: [], // 第二条线的值
                markLine1: this.infoList.lcAvgSalesEffect ? this.infoList.lcAvgSalesEffect : 0,
                markLine2: this.infoList.ytAvgSalesEffect ? this.infoList.ytAvgSalesEffect : 0
              }
              for (let i = 0; i < this.chartsData.length; i++) {
                obj.label.push(this.chartsData[i].measureDate)
                obj.value1.push(this.chartsData[i].salesEffect)
                obj.value2.push(this.chartsData[i].trafficFlowNum)
              }
              this.getChart(obj)
            }
          }
          break
      }
      switch (this.tabsRightValue) {
        case 0: // 客流
          this.salesRelativeData[3] = this.infoList.avgPassengerFlowNum // 日均客流量
          break
        case 1: // 车流
          this.salesRelativeData[3] = this.infoList.avgTrafficFlowNum // 日均车流量
          break
      }
    },
    setIndicator (key, value) {
      if (key === 0) {
        this.tabsLeftValue = value
      } else {
        this.tabsRightValue = value
      }
      this.information.isShowDatumLine1 = false
      this.information.isShowDatumLine2 = false
      this.showDatum()
      this.showData()
    },
    handleData (val) {
      this.timeType = val
      const end = new Date()
      const start = new Date()
      end.setTime(end.getTime() - 3600 * 1000 * 24 * 1)
      this.endTime = dateFormat('yyyy-MM-dd', end)
      switch (val) {
        case '近7天':
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
          this.startTime = dateFormat('yyyy-MM-dd', start)
          this.noEdit = true
          this.getData()
          break
        case '近30天':
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
          this.startTime = dateFormat('yyyy-MM-dd', start)
          this.noEdit = true
          this.getData()
          break
        case '近90天':
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
          this.startTime = dateFormat('yyyy-MM-dd', start)
          this.noEdit = true
          this.getData()
          break
        case '近一年':
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 365)
          this.startTime = dateFormat('yyyy-MM-dd', start)
          this.noEdit = true
          this.getData()
          break
        case '自定义':
          this.noEdit = false
          break
      }
    },
    setStartTime () {
      const start = new Date(this.startTime).getTime()
      const end = new Date(this.endTime).getTime()
      const difference = (end - start) / (1000 * 60 * 60 * 24)
      if (this.endTime) {
        if (start > end) {
          this.$message({
            type: 'warning',
            message: '开始时间不能大于结束时间'
          })
          return false
        }
        if (difference > 365) {
          this.$message({
            type: 'warning',
            message: '时间范围最大不超过1年'
          })
          return false
        }
        this.getData()
      }
    },
    setEndTime () {
      const start = new Date(this.startTime).getTime()
      const end = new Date(this.endTime).getTime()
      const difference = (end - start) / (1000 * 60 * 60 * 24)
      if (this.startTime) {
        if (start > end) {
          this.$message({
            type: 'warning',
            message: '开始时间不能大于结束时间'
          })
          return false
        }
        if (difference > 365) {
          this.$message({
            type: 'warning',
            message: '时间范围最大不超过1年'
          })
          return false
        }
        this.getData()
      }
    },
    // 获取图表
    getChart (obj) {
      // console.log('obj', obj)
      this.charts = echarts.init(document.getElementById('charts'))
      this.charts.setOption(this.chartsOptionLine(obj))
      this.charts.setOption({
        legend: {
          data: obj.indicator
        },
        xAxis: [
          {
            data: obj.label
          }
        ],
        series: [
          {
            name: obj.indicator[0],
            type: 'line',
            smooth: true,
            emphasis: {
              focus: 'series'
            },
            lineStyle: {
              color: '#3F8CFF'
            },
            areaStyle: {
              opacity: 1,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: 'rgba(62, 140, 255, .3)'
              }, {
                offset: 1,
                color: 'rgba(62, 140, 255, 0)'
              }])
            },
            data: obj.value1
          },
          {
            name: obj.indicator[1],
            type: 'line',
            smooth: true,
            yAxisIndex: 1,
            emphasis: {
              focus: 'series'
            },
            lineStyle: {
              color: 'rgba(160, 215, 231, 1)'
            },
            areaStyle: {
              opacity: 1,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: 'rgba(160, 215, 231, .3)'
              }, {
                offset: 1,
                color: 'rgba(160, 215, 231, 0)'
              }])
            },
            data: obj.value2
          }
        ]
      })
      this.$nextTick(() => {
        window.addEventListener('resize', () => {
          this.charts.resize()
        })
      })
    },
    chartsOptionLine (obj) {
      // console.log('objjj', obj)
      // 1.先比较两条基准线的最大值
      const line1Value = obj.markLine1 ? Number(obj.markLine1) : 0 // 第一条基准线的值
      const line2Value = obj.markLine2 ? Number(obj.markLine2) : 0 // 第二条基准线的值
      let littleMax = ''
      if (line1Value > line2Value) {
        littleMax = line1Value
      } else {
        littleMax = line2Value
      }
      // 2.比较基准线和Y轴的最大值
      const maxTemp = Math.max(...obj.value1) // 数据中的最大值
      let finalMax = ''
      if (littleMax > maxTemp) {
        finalMax = littleMax
      } else {
        finalMax = maxTemp
      }
      // 双y轴实现左右刻度线一致。设置坐标轴分割间隔。(最大值 - 最小值)/分割段数
      const maxValue1 = finalMax || 30 // 无数据时最大值默认设30
      const minValue1 = Math.min(...obj.value1) ? Math.min(...obj.value1) : 0
      const intervalValue1 = (maxValue1 - minValue1) / 5 ? (maxValue1 - minValue1) / 5 : null
      const maxValue2 = Math.max(...obj.value2) ? Math.max(...obj.value2) : 175 // 无数据时最大值默认设175
      const minValue2 = Math.min(...obj.value2) ? Math.min(...obj.value2) : 0
      const intervalValue2 = (maxValue2 - minValue2) / 5 ? (maxValue2 - minValue2) / 5 : null
      // 设置legend位置
      let rightValue = ''
      if (this.tabsLeftValue === 0) {
        rightValue = 400
      } else if (this.tabsLeftValue === 1) {
        rightValue = 225
      } else if (this.tabsLeftValue === 2) {
        rightValue = 210
      } else if (this.tabsLeftValue === 3) {
        rightValue = 425
      }
      const option = {
        color: ['#3F8CFF', '#A0D7E7'],
        legend: {
          itemHeight: 10,
          itemWidth: 10,
          itemGap: 30,
          top: 2,
          right: rightValue,
          icon: 'circle',
          textStyle: {
            color: 'rgba(255, 255, 255, .45)',
            fontFamily: 'PingFang SC',
            fontSize: 12
          }
        },
        grid: {
          top: '20%',
          left: '1%',
          right: 24,
          bottom: '5%',
          containLabel: true
        },
        // dataZoom: [{
        //   type: 'slider',
        //   height: '6',
        //   show: true,
        //   showDetail: false,
        //   xAxisIndex: [0],
        //   left: '1%',
        //   right: '1%',
        //   bottom: '2%',
        //   start: 0,
        //   borderRadius: 8,
        //   fillerColor: '#F89407',
        //   backgroundColor: '#424752',
        //   end: 80 // 初始化滚动条
        // }],
        tooltip: {
          trigger: 'axis',
          backgroundColor: 'rgba(0,0,0,0.85)',
          textStyle: {
            fontSize: 12
          },
          formatter: (params) => {
            let html = '<div style="padding:4px; font-family: PingFang SC;">' + '<p style="color:#b2b3bd; text-align:center;">' + params[0].name + '</p>'
            for (let i = 0; i < params.length; i++) {
              if (params[i].seriesName === '客流' || params[i].seriesName === '车流') {
                if (this.isShowChart) { // 接口有数据的时候
                  html += '<span style="color:#b2b3bd;">' + params[i].marker + params[i].seriesName + '： ' + '</span>' + ((params[i].data || params[i].data === 0) ? params[i].data : '0') + obj.unit[i] + '<br>'
                } else { // 接口无数据的时候
                  html += '<span style="color:#b2b3bd;">' + params[i].marker + params[i].seriesName + '： ' + '</span>' + ((params[i].data || params[i].data === 0) ? params[i].data : '-') + obj.unit[i] + '<br>'
                }
              } else {
                html += '<span style="color:#b2b3bd;">' + params[i].marker + params[i].seriesName + '： ' + '</span>' + ((params[i].data || params[i].data === 0) ? params[i].data : '-') + obj.unit[i] + '<br>'
              }
            }
            html += '</div>'
            return html
          }
        },
        xAxis: {
          type: 'category',
          offset: 6,
          boundaryGap: false,
          axisTick: {
            show: false
          },
          axisLabel: {
            color: '#808191'
          },
          axisLine: {
            lineStyle: {
              color: '#373A43'
            }
          },
          axisPointer: {
            lineStyle: {
              color: 'rgba(255,255,255,.1)'
            }
          },
          data: []
        },
        yAxis: [{
          name: obj.indicator[0] + '(' + obj.unit[0] + ')',
          interval: intervalValue1,
          splitNumber: 5,
          nameTextStyle: {
            color: '#808191',
            fontFamily: 'PingFang SC',
            fontSize: 12
          },
          nameRotate: '0.1',
          min: minValue1,
          max: maxValue1,
          type: 'value',
          axisTick: {
            show: false
          },
          position: 'left',
          splitLine: {
            lineStyle: {
              color: '#373A43',
              type: 'dashed'
            }
          },
          axisLine: {
            lineStyle: {
              color: '#373A43'
            }
          },
          axisLabel: {
            color: '#808191',
            formatter: function (value) {
              return value.toFixed(2)
            }
          }
        }, {
          name: obj.indicator[1] + '(' + obj.unit[1] + ')',
          interval: intervalValue2,
          splitNumber: 5,
          nameTextStyle: {
            color: '#808191',
            fontFamily: 'PingFang SC',
            padding: [0, 24, 0, 0],
            fontSize: 12
          },
          min: minValue2,
          max: maxValue2,
          type: 'value',
          axisTick: {
            show: false
          },
          position: 'right',
          splitLine: {
            lineStyle: {
              color: '#373A43',
              type: 'dashed'
            }
          },
          axisLine: {
            show: false
          },
          axisLabel: {
            color: '#808191',
            formatter: '{value}'
          }
        }],
        series: []
      }
      return option
    },
    // 显示基准线
    showDatum () {
      const line1 = this.information.isShowDatumLine1
      const line2 = this.information.isShowDatumLine2
      switch (this.tabsLeftValue) {
        case 0: // 销售额
          // 同业态店铺日均销售额 this.infoList.ytAvgSalesAmount
          // 同楼层店铺日均销售额 this.infoList.lcAvgSalesAmount
          if (line1 && line2) {
            this.charts.setOption(this.setDatumLine(this.infoList.lcAvgSalesAmount, this.infoList.ytAvgSalesAmount))
          } else if (line1 && !line2) {
            this.charts.setOption(this.setDatumLine(this.infoList.lcAvgSalesAmount, 'null'))
          } else if (!line1 && line2) {
            this.charts.setOption(this.setDatumLine('null', this.infoList.ytAvgSalesAmount))
          } else if (!line1 && !line2) {
            this.charts.setOption(this.setDatumLine('null', 'null'))
          }
          break
        case 1: // 交易笔数
          // 同业态店铺日均交易笔数 this.infoList.ytAvgOrderCount
          if (line1) {
            this.charts.setOption(this.setDatumLine(this.infoList.ytAvgOrderCount, 'null'))
          } else {
            this.charts.setOption(this.setDatumLine('null', 'null'))
          }
          break
        case 2: // 客单价
          // 同业态店铺平均客单价 this.infoList.ytAvgUnitPrice
          if (line1) {
            this.charts.setOption(this.setDatumLine(this.infoList.ytAvgUnitPrice, 'null'))
          } else {
            this.charts.setOption(this.setDatumLine('null', 'null'))
          }
          break
        case 3: // 销售坪效
          // 同楼层店铺日均销售坪效 this.infoList.lcAvgSalesEffect
          // 同业态店铺日均销售坪效 this.infoList.ytAvgSalesEffect
          if (line1 && line2) {
            this.charts.setOption(this.setDatumLine(this.infoList.lcAvgSalesEffect, this.infoList.ytAvgSalesEffect))
          } else if (line1 && !line2) {
            this.charts.setOption(this.setDatumLine(this.infoList.lcAvgSalesEffect, 'null'))
          } else if (!line1 && line2) {
            this.charts.setOption(this.setDatumLine('null', this.infoList.ytAvgSalesEffect))
          } else if (!line1 && !line2) {
            this.charts.setOption(this.setDatumLine('null', 'null'))
          }
          break
      }
    },
    // 设置基准线
    setDatumLine (yaxis1, yaxis2) {
      let option = {}
      if (yaxis1 === 'null' && yaxis2 === 'null') {
        option = {
          series: [
            {
              markLine: null
            }
          ]
        }
      } else if (yaxis1 !== 'null' && yaxis2 === 'null') {
        option = {
          series: [
            {
              yAxisIndex: 0,
              markLine: {
                label: {
                  show: false
                },
                symbol: 'none',
                lineStyle: {
                  width: 2
                },
                data: [
                  {
                    yAxis: yaxis1,
                    lineStyle: { color: '#89DF81' }
                  }, {
                    yAxis: 0,
                    lineStyle: { color: 'rgba(129, 110, 255, 0)' } // 隐藏
                  }
                ]
              }
            }
          ]
        }
      } else if (yaxis1 === 'null' && yaxis2 !== 'null') {
        option = {
          series: [
            {
              yAxisIndex: 0,
              markLine: {
                label: {
                  show: false
                },
                symbol: 'none',
                lineStyle: {
                  width: 2
                },
                data: [
                  {
                    yAxis: 0,
                    lineStyle: { color: 'rgba(137, 223, 129, 0)' } // 隐藏
                  }, {
                    yAxis: yaxis2,
                    lineStyle: { color: '#816EFF' }
                  }
                ]
              }
            }
          ]
        }
      } else if (yaxis1 !== 'null' && yaxis2 !== 'null') {
        option = {
          series: [
            {
              yAxisIndex: 0,
              markLine: {
                label: {
                  show: false
                },
                symbol: 'none',
                lineStyle: {
                  width: 2
                },
                data: [
                  {
                    yAxis: yaxis1,
                    lineStyle: { color: '#816EFF' }
                  }, {
                    yAxis: yaxis2,
                    lineStyle: { color: '#89DF81' }
                  }
                ]
              }
            }
          ]
        }
      }
      return option
    },
    exportOutFunc () {
      this.axios.post(api.exportDaySalesData, { storeCode: this.filtroStore, salesStartDate: this.startTime, salesEndDate: this.endTime }).then(res => {
        if (res.data.status.code === '10000') {
          this.downLoadFile('/apiexport/dp' + res.data.data)
        } else {
          this.$message({
            type: 'warning',
            message: res.data.status.msg
          })
        }
      })
    },
    downLoadFileAjax (url) {
      return this.axios({ // 用axios发送post请求
        method: 'get',
        url: url, // 请求地址
        responseType: 'blob', // 表明返回服务器返回的数据类型
        headers: {
          'Content-Type': 'application/json'
        }
      })
    },
    downLoadFile (url) {
      this.loading = true
      this.downLoadFileAjax(url).then((res) => { // 处理返回的文件流
        const blob = new Blob([res.data])
        const temp = url.split('/')
        const fileName = temp[temp.length - 1]
        // 兼容ie.xlsx
        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveBlob(blob, fileName)
        } else {
          const elink = document.createElement('a')
          elink.download = fileName
          elink.style.display = 'none'
          elink.href = URL.createObjectURL(blob)
          document.body.appendChild(elink)
          elink.click()
          setTimeout(() => {
            URL.revokeObjectURL(elink.href) // 释放URL 对象
            document.body.removeChild(elink)
          }, 1000)
        }
        this.loading = false
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.view1
  padding-top 60px
  background #272930
  opacity 1
  border-radius 0 0 2px 2px
  .selectBtn-view
    margin-top 17px
    padding-left 24px
    // height 33px
    line-height 33px
    display flex
    flex-flow wrap
    .type-item
      // height 50px
      margin-top 15px
      display flex
      font-size 14px
      font-family PingFang SC
      color #B2B3BD
      .type-item-btn
        padding 0 3px
        height 33px
        border 1px solid #373A43
        opacity 1
        border-radius 2px
        span
          display inline-block
          width 77px
          height 27px
          line-height 27px
          font-size 14px
          font-family PingFang SC
          font-weight 500
          color rgba(255, 255, 255, 0.5)
          text-align center
          margin-right -1px
          cursor pointer
          &.active
            background #FFA134
            color #fff
            border-color #FFA134
        span:first-child
          border-radius 2px 0px 0px 2px
        span:last-child
          border-radius 0px 2px 2px 0px
      .type-item-select
        background-color #373A43
        .segmentation
          color #424752
        .isDisabled
          color rgba(128, 129, 145, 0.5)
        span
          color #B2B3BD
        & >>> .el-select
          width 112px
        & >>> .el-date-editor
          width 120px
          // width 144px
          input
            text-align center
  .checkBox
    position absolute
    right 48px
    & label:first-child >>> .el-checkbox__inner
      border 1px #808191 solid !important
    & label:first-child >>> .el-checkbox__input.is-checked .el-checkbox__inner
      background-color #89DF81 !important
      border-color #89DF81 !important
    & label:first-child >>> .is-checked + span
      color #fff !important
    & label:first-child >>> .el-checkbox__input.is-checked
      border-color #89DF81 !important
    & label:nth-child(2) >>> .el-checkbox__inner
      border 1px #808191 solid !important
    & label:nth-child(2) >>> .el-checkbox__input.is-checked .el-checkbox__inner
      background-color #816EFF !important
      border-color #816EFF !important
    & label:nth-child(2) >>> .is-checked + span
      color #fff !important
    & label:nth-child(2) >>> .el-checkbox__input.is-checked
      border-color #816EFF !important
  .analysis
    display flex
    margin-top 63px
    height 420px
    .analysis-chart
      flex 1
      .charts-box
        width 100%
        height 420px
    .analysis-data
      flex 0 0 480px
      .info-ul
        li:nth-child(2), li:nth-child(4)
          width 231px
          padding-left 40px
        li
          padding-left 24px
          margin-top 45px
          display inline-block
          width 245px
          box-sizing border-box
          text-align left
          font-weight 500
          color #70747D
          p
            font-size 14px
            line-height 25px
      .pl-0
        li:nth-child(4)
          padding-left 24px
      .num-span
        color #fff
        font-size 26px
        font-weight bold
.view2
  margin-top 14px
  padding-bottom 24px
  background #272930
  border-radius 2px
  .exportOut
    margin -4px 25px 30px 0
    font-size 16px
    font-weight 400
    color #B2B3BD
    cursor pointer
    &:before
      margin-right 6px
      font-family 'iconfont'
      content '\e60b'
    &:hover
      color #f89407
  .w-table
    margin 0 24px
    padding 0 !important
    height 442px
    overflow-y auto
    table
      width 100%
      thead
        tr
          height 54px
          color #B2B3BD
          font-size 14px
          th
            border-bottom 1px solid #373A43
            text-align left
      tbody
        tr
          height 49px
          color #808191
          font-size 14px
          &:hover td
            background-color #23252b
          td
            border none
            border-bottom 1px solid #373A43
            text-align left
.clear
  clear both
.mr-70
  margin-right 70px
// elm-ui样式
// 1.下拉框
/deep/ .el-input__inner
  height 33px
  background-color #373A43
  color #808191
  border none
  border-radius 2px
  padding 0 18px
// 2.日期组件样式
// （1）日期icon
/deep/ .el-input__prefix
  display none
/deep/ .el-date-editor .el-input__suffix
  display none
// （2）输入框禁止点击时
/deep/ .is-disabled .el-input__inner
  color rgba(128, 129, 145, 0.5) !important
  background-color #373a43 !important
</style>
